.Login {
  display:flex;
  justify-content: center;
  align-items: center;  
  height: 100vh;
  width: 100vw;
  background: #eff0f3;

  .login-wrap {
    width: 380px;
  }
  .login-logo {
    height: 34px;
    background: url('/assets/images/home/header/logo.png') no-repeat 50%;
    margin: 0 auto 55px;
  }
  .page-title {
    font-size: 60px;
    font-weight: bold;
    letter-spacing: -3px;
    text-align: center;
    margin-bottom: 40px;
  }
  .inputbox {
    text-align: center;
    .MuiFormControl-root {
      font-size: 16px;
      flex-direction: initial;
      margin-bottom: 10px;
  
      .MuiOutlinedInput-root {
        width: 350px;
        font-size: 16px;
        color: #636363;
        background-color: #fff !important;
        border: 1px solid #fff;
        border-radius: 10px;
  
        input {
          font-size: 16px !important;
          height: 58px !important;
          padding: 0 20px !important;
        }              
      }  
      .MuiOutlinedInput-root fieldset {
        border: 1px solid #d3d7dc;
      }
      .MuiOutlinedInput-root:hover fieldset {
        border: 2px solid #415282; 
      } 
      .MuiOutlinedInput-root.Mui-focused fieldset {
        border: 2px solid #415282;  
      }       
    }
  }

  /* CheckBox */
  .Checkbox.MuiFormControlLabel-root {
    height: 28px;
    padding-left: 15px;

    .MuiSvgIcon-root {
      width: 21px;
      height: 21px;
      background: #eff0f3;
      border: none;
    }
    .MuiCheckbox-root {
      color: #000;
    }
    .MuiTypography-body1 {
      font-size: 13px;
      color: #777;
    }
  }

  .login-btn {
    font-size: 16px !important;
    color: #fff;
    width: 350px;
    height: 60px;
    border-radius: 10px !important;
    background-color: #333c4d !important;
    margin: 20px 15px;
    .MuiButton-label {
      color: inherit;
    }
  }   

  .login-menu {
    width: 350px;
    margin: 0 auto;
    li {
      float: left;
      width: 50%;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: -1px;
      line-height: 30px;
      text-align: center;
      .usermenu {
        display: inline-block;
        width: calc(100% - 1px);
      }
    }
    > li + li:before {
      display: inline-block;
      content: '';
      width: 1px;
      height: 10px;
      background-color: #cfcfcf;
      vertical-align: middle;
    }     
  }
}  

@media screen and (max-width:750px) {
  .Login {
    .login-wrap {
      width: 60rem;
    }
    .login-logo {
      height: 44px;
      background: url('/assets/images/home/header/logo.png') no-repeat 50% / contain;
      margin: 0 auto 10rem;
    }
    .page-title {
      font-size: 6rem;
      font-weight: bold;
      letter-spacing: -3px;
      text-align: center;
      margin-bottom: 4rem;
    }
    .inputbox {
      text-align: center;
      .MuiFormControl-root {
        font-size: 2.8rem;
        flex-direction: initial;
        margin-bottom: 2rem;
    
        .MuiOutlinedInput-root {
          width: 60rem;
          font-size: 2.8rem;
          color: #636363;
          background-color: #fff !important;
          border: 1px solid #fff;
          border-radius: 1rem;
    
          input {
            height: 9rem;
            padding: 0 4rem;
          }              
        }  
      }
    }
  
    /* CheckBox */
    .Checkbox.MuiFormControlLabel-root {
      height: 2.8rem;
      padding-left: 1.5rem;
      margin-top: 2rem;

      .MuiSvgIcon-root {
        width: 3rem;
        height: 3rem;
        background: #eff0f3;
        border: none;
      }
      .MuiCheckbox-root {
        color: #000;
      }
      .MuiTypography-body1 {
        font-size: 2.6rem;
        color: #777;
      }
    }
  
    .login-btn {
      font-size: 2.8rem;
      color: #fff;
      width: 100%;
      height: 9rem;
      border-radius: 1rem !important;
      background-color: #333c4d !important;
      margin: 4rem 0;
      .MuiButton-label {
        color: inherit;
      }
    }   
  
    .login-menu {
      width: 60rem;
      margin: 0 auto;
      li {
        float: left;
        width: 50%;
        font-size: 2.6rem;
        font-weight: 500;
        letter-spacing: -1px;
        line-height: 3rem;
        text-align: center;
      }
      > li + li:before {
        display: inline-block;
        content: '';
        width: 1px;
        height: 2.4rem;
        background-color: #cfcfcf;
        vertical-align: middle;
      }     
    }
  }  
}  