.popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.popup-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 99999;
}
.popup-control {
  position: fixed;
  top: calc(50% - 25px);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 99999999;
  &.popup-left-control {
    left: 50px;
    background: #eee url('/assets/images/home/icon_prev.png') no-repeat 50%;
    background-size: 14px auto;
  }
  &.popup-right-control {
    right: 50px;
    background: #eee url('/assets/images/home/icon_next.png') no-repeat 50%;
    background-size: 14px auto;
  }  
  &:hover {
    background-color: #fff;
  }
}
.popup-layer {
  // position:fixed;
  position: relative;
  border:solid 1px #ddd;
  z-index:10000;
  box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
  background-color:#fff;
  max-width:100%;
  z-index: 999999;
  background: #fff;
  opacity: 1;

  .popup-subject {
    padding: 10px;
    font-size: 16px;
    font-family: 'NanumSquare', sans-serif;
    width: 100%;
    color: #666;
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-size-adjust: none;
    word-break: keep-all;
  }

  .popup-preview-contents {
    width:100%;
    height:calc(100% - 88px);
    overflow-y:auto;
    overflow-x:hidden;
  }

  .popup-not-today {
    line-height:40px;
    font-size:14px;
  }

  .popup-button-area {
    padding: 15px;
    color: #fff;
    height: 40px;
    text-align: right;
    padding: 0px 20px;
    border-top: 1px solid #e5e5e5;
    background-color: #939393;
    
    button {
      color: #fff;
      background-color: transparent; 
      margin-top: 8px !important;
    } 
  }
  
  .popup-header {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    letter-spacing: -1px;
    line-height: 50px;    
    height: 50px;
    padding-left: 20px;
    background-color: #1e57a1;
  }
}