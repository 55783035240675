.board-home {
  .board-header {
    margin-bottom: 10px;
    .cnt {
      float: left;
      font-size: 14px;
      font-weight: 500;
      color: #777;
      letter-spacing: -1px;
      line-height: 37px;
    }
    .selectBox {
      width: 120px;
      height: 35px;
      .MuiInputBase-root .MuiInputBase-input {
        height: 35px;
        border-radius: 0;
        border-right: none;
      }
    }
    .input {
      width: 190px;
      height: 35px;
      margin-right: 5px;      
      .MuiOutlinedInput-input {
        height: 37px;
        padding: 0 10px;
        border-left: none;
      }
      .MuiOutlinedInput-root {
        border-radius: 0;
      }
    }
    .HBtn {
      height: 37px;
    }
  }
  .list-table.board-table {
    border-top: 2px solid #333c4d;
    th, td {
      height: 47px;
      border-left: none;
      border-right: none;
    }
  }
  .pagination {
    margin: 40px auto 150px;

    .MuiPagination-ul {
      justify-content: center;
      
      .MuiPaginationItem-root {
        width: 35px;
        min-width: 35px;
        height: 35px;
        border: 1px solid #d5d5d5;  
        padding: 0;
        margin: 0 5px 0 0;
        border-radius: 4px;
        color: #636363;
  
        svg {
          color: #000;
        }
  
        &.Mui-selected {
          background: #f2f2f2;    
        }
      }  
      .MuiPaginationItem-ellipsis {
        border: none;
      }
    }  
  }
  .list-table.board-view-table {
    border-top: 2px solid #333c4d;
    .col1 {width: 15%;}
    .col2 {width: 85%;}
    td {
      text-align: left;
      padding: 30px;
      border-left: none;
      border-right: none;
    }
    .title-tr {
      background: #f5f6f7;
      .title {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -1px;
        padding-bottom: 20px;
      }
      .date {
        font-size: 14px;
        font-weight: 500;
        color: #666;
        letter-spacing: -1px;
        span {
          color: #666;
          &:before {
            display: inline-block;
            content: '';
            width: 1px;
            height: 10px;
            margin: 0 10px;
            background-color: #cfcfcf;
            vertical-align: middle;
          }  
        }
      }
    }
    .board-contents {
      td {
        text-align: center;
        padding: 40px;
        img {
          max-width: 100%;
        }
      }      
    }
    .file-tr {
      th {
        font-size: 13px;
      }
      td {
        text-align: left;
        .file {
          display: block;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: -1px;
          line-height: 24px;
          &:before {
            display: inline-block;
            content: '';
            width: 2px;
            height: 2px;
            margin-right: 5px;
            background-color: #777;
            vertical-align: middle;
          }  
        }
      }
    }
  }
  .Btn.list-btn {
    display: block;
    width: 260px;
    height: 55px;
    font-size: 18px !important;
    color: #222;
    border-radius: 0;
    border: 1px solid #d3d7dc;
    margin: 60px auto 150px;
  }
}

@media screen and (max-width:750px) {
  .board-home {
    .board-header {
      margin-bottom: 4rem;
      .cnt {
        float: none;
        font-size: 2.4rem;
        font-weight: 500;
        color: #777;
        letter-spacing: -1px;
        line-height: 1;
        margin-bottom: 2rem;
      }
      .selectBox {
        font-size: 2.4rem;
        width: calc(100vw - 9.6rem);
        height: 9rem;
        margin-bottom: 2rem;
        .MuiInputBase-root .MuiInputBase-input {
          font-size: 2.4rem;
          height: 9rem;
          padding: 0 2rem;
          border-radius: 0;
          border-right: 1px solid #d6d6d6;
        }
      }
      .input {
        font-size: 2.4rem;
        width: calc(100vw - 9.6rem);
        height: 9rem;
        margin: 0 0 2rem;      
        .MuiOutlinedInput-input {
          font-size: 2.4rem;
          height: 9rem;
          padding: 0 2rem;
          border-left: none;
        }
        .MuiOutlinedInput-root {
          border-radius: 0;
        }
      }
      .HBtn {
        width: calc(100vw - 9.6rem);
        height: 9rem;
      }
    }
    .list-table.board-table {
      width: calc(100vw - 9.6rem);
      border-top: 2px solid #333c4d;
      th, td {
        display: block;
        width: calc(100vw - 9.6rem);
        height: auto;
        border: none;
        text-align: left;
      }
      thead {
        display: none;
      }
      tr {
        border-bottom: 1px solid #e3e7eb;
        td:nth-child(1),td:nth-child(3),td:nth-child(5) {
          display: none;
        }
        td:nth-child(2) {
          font-size: 2.8rem;
          line-height: 4rem;
          padding: 4rem 0 3rem;
        }
        td:nth-child(4) {
          font-size: 2.2rem;
          line-height: 3rem;
          padding: 0 0 4rem;
        }
      }
    }
    .pagination {
      margin: 8rem auto 15rem;

      .MuiPagination-ul {
        justify-content: center;
        
        .MuiPaginationItem-root {
          font-size: 2rem;
          width: 5rem;
          min-width: 5rem;
          height: 5rem;
          border: 1px solid #d5d5d5;  
          padding: 0;
          margin: 0 0.3rem;
          border-radius: 1rem;
          color: #636363;
    
          svg {
            color: #000;
          }
    
          &.Mui-selected {
            background: #f2f2f2;    
          }
        }  
        .MuiPaginationItem-ellipsis {
          border: none;
        }
      }  
    }
    .list-table.board-view-table {
      width: calc(100vw - 9.6rem);
      border-top: 2px solid #333c4d;
      .col1 {width: 30%;}
      .col2 {width: 70%;}
      td {
        text-align: left;
        padding: 3rem;
        border-left: none;
        border-right: none;
      }
      .title-tr {
        background: #f5f6f7;
        .title {
          font-size: 3rem;
          font-weight: bold;
          line-height: 4rem;
          letter-spacing: -1px;
          padding-bottom: 20px;
        }
        .date {
          font-size: 2.2rem;
          font-weight: 500;
          color: #666;
          letter-spacing: -1px;
          span {
            color: #666;
            &:before {
              display: inline-block;
              content: '';
              width: 1px;
              height: 10px;
              margin: 0 2rem;
              background-color: #cfcfcf;
              vertical-align: middle;
            }  
          }
        }
      }
      .board-contents {
        td {
          text-align: center;
          padding: 4rem;
          width: calc(100vw - 9.6rem);
        }      
      }
      .file-tr {
        th {
          font-size: 2.4rem;
        }
        td {
          text-align: left;
          .file {
            display: block;
            font-size: 2.4rem;
            font-weight: 300;
            letter-spacing: -1px;
            line-height: 4.4rem;
            &:before {
              display: inline-block;
              content: '';
              width: 2px;
              height: 2px;
              margin-right: 0.5rem;
              background-color: #777;
              vertical-align: middle;
            }  
          }
        }
      }
    }
    .Btn.list-btn {
      display: block;
      width: 100%;
      height: 9rem;
      font-size: 2.8rem;
      border-radius: 0;
      margin: 4rem auto 15rem;
    }
  }
}  