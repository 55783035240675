body {
  background: #ddd;
}
.btn-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 10px;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #ddd;
  text-align: right;
  z-index: 9;
}

.print-body {
  position: relative;
  width: 20.9cm;
  height: 29.5cm;
  padding: 50px 40px 0;
  margin: 0 auto 10px;
  background: #fff;
}

/* 검사신청서 */
.freezer-print1 {
  .print-header {
    .title {
      font-size: 36px;
      font-weight:500;
      text-align: center;
      margin-bottom: 50px;
    }
    .guide-txt {
      font-size: 13px;
      margin-bottom: 25px;
    }
    .no {
      font-size: 13px;
      margin-bottom: 10px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .report-tbl {
    table-layout: fixed;
    th {
      font-size: 13px;
      height: 25px;
      border: 1px solid #aaa;
      text-align: center;
    }
    td {
      font-size: 12px;
      height: 21px;
      border: 1px solid #aaa;
      text-align: center;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &.tl {
        text-align: left;
        padding-left: 5px;
      }
    }
    tbody th {
      font-size: 13px;
      font-weight: 500;
    }
    tfoot td {
      font-size: 14px;
    }
  }

  .print-footer {
    position: absolute;
    left: 40px;
    bottom: 40px;
    width: calc(100% - 80px);
    .footer1 {
      .sign {
        float: left;
        width: 100px;
        height: 75px;
        border: 1px solid #aaa;
        margin-left: 100px;
        .th {
          font-size: 14px;
          font-weight: 500;
          line-height: 23px;
          height: 25px;
          text-align: center;
          border-bottom: 1px solid #222;
        }
      }
      .info {
        float: right;
        width: 250px;
        th, td {
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          height: 25px;
        }
      }
    }
    .footer2 {
      padding-top: 30px;
      .ceo {
        font-size: 25px;
        margin-bottom: 20px;
        span {
          font-size: 12px;
          padding-left: 5px;
        }
      }
      .com {
        float: left;
        font-size: 12px;
      }
      .document-no {
        float: right;
        font-size: 13px;
      }
    }
  }
}

/* 냉동기검사신청서 */
.freezer-print2 {
  .print-header {
    .document-no {
      font-size: 12px;
      margin-bottom: 30px;
    }
    .title {
      font-size: 35px;
      font-weight:500;
      text-align: center;
      margin-bottom: 20px;
    }
    .guide-txt {
      font-size: 12px;
    }
    .date {
      height: 20px;
      background: #ddd;
      margin: 5px 0 10px;
      div {
        float: left;
        width: calc(100% / 3);
        font-size: 12px;
        line-height: 20px;
        padding-left: 10px;
      }
    }
  }

  .report-tbl {
    table-layout: fixed;
    margin: 10px 0;
    th {
      font-size: 12px;
      height: 35px;
      border: 1px solid #aaa;
      text-align: center;
    }
     td {
      font-size: 12px;
      line-height: 1.2;
      height: 35px;
      padding: 5px;
      border: 1px solid #aaa;
      text-align: left;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &.tc {
        text-align: center;
      }
      &.tr {
        text-align: right;
      }
    }
  }

  .print-footer {
    .footer1 {
      .hope-date {
        font-size: 12px;
        line-height: 35px;
        height: 35px;
        border-top: 1px solid #222;
        border-bottom: 1px solid #222;
      }
      .report-txt {
        font-size: 16px;
        text-indent: 20px;
        padding-left: 50px;
        margin: 10px 0 20px;
      }
      .date {
        font-size: 14px;
        text-align: right;
      }
      .user {
        font-size: 12px;
        text-align: right;
        margin: 10px 0 20px;
        span {
          font-size: 14px;
        }
      }
      .ceo {
        font-size: 24px;
        padding-left: 50px;
        margin-bottom: 20px;
        span {
          font-size: 12px;
          padding-left: 5px;
        }
      }
    }
    .footer2 {
      .report-tbl {
        margin-bottom: 2px;
        tr {
          th {border-left: none;}
          td:nth-last-child(1) {border-right: none;}
        }
      }
      .step-tbl {
        border-top: 1px solid #222;
        tr {
          border-top: 1px solid #222;
          border-bottom: 1px solid #222;
        }
        th {
          font-size: 12px;
          font-weight: 500;
          height: 25px;
          background: #ddd;
        }
        .step {
          height: 80px;
          /*background: url('/assets/images/inp_process.png') no-repeat 50% / contain;*/
        }
      }
      .document-size {
        float: right;
        font-size: 10px;
      }
    }
  }
}

/* 냉동기검사표 */
.freezer-print3 {
  .print-header {
    .title {
      font-size: 26px;
      font-weight:500;
      text-align: center;
      text-decoration: underline;
      margin-bottom: 30px;
    }
    .date {
      div {
        font-size: 13px;
      }
    }
  }

  .report-tbl {
    table-layout: fixed;
    margin: 10px 0;
    th {
      font-size: 12px;
      height: 25px;
      border: 1px solid #aaa;
      text-align: center;
    }
     td {
      font-size: 12px;
      height: 25px;
      padding: 0 10px;
      border: 1px solid #aaa;
      text-align: left;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &.tc {
        text-align: center;
      }
      .bold {
        margin-left: -5px;
      }
      span {padding: 0 20px;}
      .space {padding-left: 16px;}
    }
  }

  .print-footer {
    .footer1 {
      padding-top: 50px;
      .user {
        float: left;
        font-size: 12px;
        padding-top: 25px;
        span {
          display: inline-block;
          width: 160px;
        }
      }
      .sign {
        float: right;
        width: 100px;
        height: 75px;
        border: 1px solid #aaa;
        margin-bottom: 20px;
        .th {
          font-size: 13px;
          font-weight: 500;
          line-height: 23px;
          height: 25px;
          text-align: center;
          border-bottom: 1px solid #222;
        }
      }
      .com {
        float: left;
        font-size: 12px;
      }
      .document-no {
        float: right;
        font-size: 12px;
      }
    }
  }
}

/* 고압가스냉동기 자체검사성적서 */
.freezer-print4 {
  .print-header {
    .title {
      font-size: 26px;
      font-weight:500;
      text-align: center;
      text-decoration: underline;
      margin-bottom: 30px;
    }
    .no{
      font-size: 12px;
    }
  }

  .report-tbl {
    table-layout: fixed;
    margin: 10px 0;
    th {
      font-size: 12px;
      font-weight: 500;
      height: 25px;
      border: 1px solid #aaa;
      text-align: center;
    }
     td {
      font-size: 12px;
      height: 25px;
      padding: 0;
      border: 1px solid #aaa;
      text-align: center;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &.tc {
        text-align: center;
      }
      .bold {
        margin-left: -5px;
      }
      .unit {
        float: right;
        padding-right: 10px;
      }
    }
  }

  .print-footer {
    position: absolute;
    left: 40px;
    bottom: 50px;
    width: calc(100% - 80px);
    .footer1 {
      padding-top: 50px;
      .report-txt {
        font-size: 12px;
        line-height: 1.2;
        text-indent: 20px;
        padding-left: 50px;
        margin: 10px 0 20px;
      }
      .date {
        font-size: 12px;
        text-align: center;
      }
      .user {
        font-size: 12px;
        text-align: right;
        margin: 10px 0 20px;
        span {
          font-size: 14px;
          padding-right: 30px;
        }
      }
      .sign {
        float: right;
        width: 100px;
        height: 75px;
        border: 1px solid #aaa;
        margin-bottom: 20px;
        .th {
          font-size: 13px;
          font-weight: 500;
          line-height: 23px;
          height: 25px;
          text-align: center;
          border-bottom: 1px solid #222;
        }
      }
      .com {
        float: left;
        font-size: 12px;
      }
      .document-no {
        float: right;
        font-size: 12px;
      }
    }
  }
}

@page {
  size: A4 portrait;
  margin: 0;
}

@media print {
  body {
    padding: 0;
  }
  .btn-area {
    display:none;
  }
  .report {
    margin: 0;
    width: 100%;
    page-break-after: always;
  }
}

