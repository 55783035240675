/* footer home */
.footer-home {
  .mobile-only {display: none;}
  .footer1 {
    display:flex;
    justify-content: center;
    align-items: center;
    height: 78px;
    background: #fff;
    border-top: 1px solid #ececec;
    .flink {
      li {
        float: left;
        padding: 0 10px;
        > a {
          display: block;
          width: 175px;
          height: 40px;
          text-indent: -9999px;
          background-size: contain;
        }
        &:nth-child(1) > a {background-image: url('/assets/images/home/footer/flink01.png');}
        &:nth-child(2) > a {background-image: url('/assets/images/home/footer/flink02.png');}
        &:nth-child(3) > a {background-image: url('/assets/images/home/footer/flink03.png');}
        &:nth-child(4) > a {background-image: url('/assets/images/home/footer/flink04.png');}
      }    
    }
  }
  .footer2 {
    display:flex;
    justify-content: center;
    align-items: center;    
    height: 59px;
    background: #f7f7f7;
    border-top: 1px solid #ececec;
    .fmenu {
      li {
        float: left;
        padding: 0 25px;
        .fmenu-list {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
  .footer3 {  
    &.inner {
      padding: 40px 0;
    }    
    border-top: 1px solid #ececec;
    .footer-logo {
      float: left;
      margin: 0 50px 0 40px;
    }
    .addr {
      float: left;
      > p {
        font-size: 12px;
        font-weight: 500;      
        color: #777;
        line-height: 23px;
      }
      .line {
        display: inline-block;
        width: 1px;
        height: 9px;
        margin: 0 10px;
        background-color: #bcbcbc;
      }
    }
  }  
  .to-top {
    position: fixed;
    bottom: 60px;
    right: 60px;
    width: 50px;
    height: 50px;
    min-width: auto;
    background: #fff url('/assets/images/home/footer/icon_top.png') no-repeat 50%;
    border: 1px solid #d7d7d7;
    border-radius: 50%;
    z-index: 9;
  }
}

@media screen and (max-width:1000px) {
  .footer-home {
    .footer1 {
      .flink {
        li {
          padding: 0 5px;
        }
      }
    }
    .footer3 {  
      .footer-logo {
        float: none;
      }
      .addr {
        float: none;
        margin: 30px 0 0 50px;
      }
    }    
  }  
}  

@media screen and (max-width:750px) {
  .footer-home {
    .pc-only {display: none;}
    .mobile-only {display: block;}
    .footer1 {
      height: 29rem;
      padding: 0 2.3rem;
      .flink {
        li {
          float: left;
          width: 50%;
          padding: 1.5rem 2.5rem;
          > a {
            display: block;
            width: 30.3rem;
            height: 8rem;
          }
          &:nth-child(1) > a {background-image: url('/assets/images/home/footer/flink01m.png');}
          &:nth-child(2) > a {background-image: url('/assets/images/home/footer/flink02m.png');}
          &:nth-child(3) > a {background-image: url('/assets/images/home/footer/flink03m.png');}
          &:nth-child(4) > a {background-image: url('/assets/images/home/footer/flink04m.png');}
        }    
      }
    }
    .footer2 {
      height: 11.2rem;
      padding: 0 4.8rem;
      .fmenu {
        width: 100%;
        li {
          float: left;
          padding: 0;
          .fmenu-list {
            font-size: 2.4rem;
            font-weight: 500;
          }
          &:nth-child(2) {
            padding: 0 6.7rem;
          }
        }
      }
    }
    .footer3 {  
      padding: 4.5rem 4.8rem 6rem !important;
      border-top: 1px solid #ececec;
      .footer-logo {
        float: none;
        margin: 0;
        img {
          width: 48.3rem;
        }
      }
      .addr {
        float: none;
        margin: 3.5rem 0 0;
        > p {
          font-size: 2.2rem;
          font-weight: 500;      
          color: #777;
          line-height: 3.4rem;
        }
        .line {
          display: inline-block;
          width: 1px;
          height: 1.8rem;
          margin: 0 2rem;
          background-color: #bcbcbc;
          &.pc-only{ display: none;}
        }
      }
    }  
    .to-top {
      display: none;
    }
  }
}  