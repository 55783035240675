@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import url('assets/fonts/o2oz_icon/style.css');

/* Noto Sans KR (korean) */

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url(assets/fonts/NotoSansKR-Thin.woff2) format('woff2'),
       url(assets/fonts/NotoSansKR-Thin.woff) format('woff'),
       url(assets/fonts/NotoSansKR-Thin.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(assets/fonts/NotoSansKR-Light.woff2) format('woff2'),
       url(assets/fonts/NotoSansKR-Light.woff) format('woff'),
       url(assets/fonts/NotoSansKR-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/NotoSansKR-Regular.woff2) format('woff2'),
       url(assets/fonts/NotoSansKR-Regular.woff) format('woff'),
       url(assets/fonts/NotoSansKR-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url(assets/fonts/NotoSansKR-Medium.woff2) format('woff2'),
       url(assets/fonts/NotoSansKR-Medium.woff) format('woff'),
       url(assets/fonts/NotoSansKR-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url(assets/fonts/NotoSansKR-Bold.woff2) format('woff2'),
       url(assets/fonts/NotoSansKR-Bold.woff) format('woff'),
       url(assets/fonts/NotoSansKR-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url(assets/fonts/NotoSansKR-Black.woff2) format('woff2'),
       url(assets/fonts/NotoSansKR-Black.woff) format('woff'),
       url(assets/fonts/NotoSansKR-Black.otf) format('opentype');
}

@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Regular'), url('assets/fonts/Roboto/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Italic'), url('assets/fonts/Roboto/Roboto-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Bold'), url('assets/fonts/Roboto/Roboto-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Bold Italic'), url('assets/fonts/Roboto/Roboto-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Thin'), url('assets/fonts/Roboto/Roboto-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Thin Italic'), url('assets/fonts/Roboto/Roboto-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Light';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Light'), url('assets/fonts/Roboto/Roboto-Light.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Light Italic'), url('assets/fonts/Roboto/Roboto-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Medium'), url('assets/fonts/Roboto/Roboto-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Medium Italic'), url('assets/fonts/Roboto/Roboto-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Black';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Black'), url('assets/fonts/Roboto/Roboto-Black.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Black Italic'), url('assets/fonts/Roboto/Roboto-BlackItalic.woff') format('woff');
}