body {
  width: 100%;
  line-height: 1;
}
body * {
  font-family: 'Noto Sans KR', sans-serif;
  letter-spacing: 0px;
  box-sizing: border-box;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
button {
  border: none;
  outline: none;
  cursor: pointer;
}
a {
  cursor: pointer;
  color: #222;
  text-decoration: none;
}
a:hover {
  color: #222;
  text-decoration: underline;
}
.hover {
  color: #222;
  text-decoration: none;
  &:hover {
    background-color: #f2f2f2;
  }
}
p {
  padding: 0;
  margin: 0;
}
table {
  width: 100%;
  border-collapse:collapse;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
.bold {font-weight: bold !important;}
.vt {vertical-align: top !important;}
.vs {vertical-align: sub !important;}
.fl {float: left;}
.fr {float: right;}
.cp {cursor: pointer;}
.left {text-align: left !important;}
.right {text-align: right !important;}
.center {text-align: center !important;}
.hidden {display: none !important;}
.f12 {font-size: 12px !important;}
.f14 {font-size: 14px !important;}
.f20 {font-size: 20px !important;}

.h100 {height: 100%;}

.p5 {padding: 5px !important;}
.p10 {padding: 10px !important;}
.p20 {padding: 20px !important;}

.pt0 {padding-top: 0px !important;}
.pt5 {padding-top: 5px !important;}
.pt10 {padding-top: 10px !important;}
.pt15 {padding-top: 15px !important;}
.pt20 {padding-top: 20px !important;}

.pl10 {padding-left: 10px !important;}
.pl20 {padding-left: 20px !important;}
.pl40 {padding-left: 40px !important;}
.pl5 {padding-left: 5px !important;}

.pr5 {padding-right: 5px !important;}

.pb0 {padding-bottom: 0px !important;}
.pb5 {padding-bottom: 5px !important;}
.pb10 {padding-bottom: 10px !important;}
.pb15 {padding-bottom: 15px !important;}
.pb20 {padding-bottom: 20px !important;}
.pb25 {padding-bottom: 25px !important;}

.mt0 {margin-top: 0px !important;}
.mt5 {margin-top: 5px !important;}
.mt7 {margin-top: 7px !important;}
.mt10 {margin-top: 10px !important;}
.mt20 {margin-top: 20px !important;}
.mt30 {margin-top: 30px !important;}
.mt35 {margin-top: 35px !important;}
.mt40 {margin-top: 40px !important;}

.mb0 {margin-bottom: 0px !important;}
.mb5 {margin-bottom: 5px !important;}
.mb10 {margin-bottom: 10px !important;}
.mb15 {margin-bottom: 15px !important;}
.mb20 {margin-bottom: 20px !important;}
.mb30 {margin-bottom: 30px !important;}

.ml2 {margin-left: 2px !important;}
.ml3 {margin-left: 3px !important;}
.ml4 {margin-left: 4px !important;}
.ml5 {margin-left: 5px !important;}
.ml6 {margin-left: 6px !important;}
.ml10 {margin-left: 10px !important;}
.ml20 {margin-left: 20px !important;}

.mr3 {margin-right: 3px !important;}
.mr4 {margin-right: 4px !important;}
.mr5 {margin-right: 5px !important;}
.mr6 {margin-right: 6px !important;}
.mr10 {margin-right: 10px !important;}
.mr20 {margin-right: 20px !important;}
.mr50 {margin-right: 50px !important;}

.w50p {width: 50%;text-align: left;}

.cred {color: #bc3434;}
.corange {color: #ed7705;}
.cblue {color: #274587;}
.cblack {color: #222;}

.hover_red:hover {
  cursor: pointer;
  color: #ec5e00 !important;
}


/* MyIcons */
.icons-dashboard {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('/assets/images/icon.png');
  background-position: calc(30px * 0 - 20px) calc(30px * 0 - 20px);
}
.icons-basic {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('/assets/images/icon.png');
  background-position: calc(30px * (-1) - 20px) calc(30px * 0 - 20px);
}
.icons-inspection {
  display: inline-block;
  width: 17px;
  height: 16px;
  background: url('/assets/images/icon.png');
  background-position: calc(30px * (-2) - 20px) calc(30px * 0 - 20px);
}
.icons-businessTrip {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('/assets/images/icon.png');
  background-position: calc(30px * (-3) - 20px) calc(30px * 0 - 20px);
}
.icons-receive {
  display: inline-block;
  width: 14px;
  height: 16px;
  background: url('/assets/images/icon.png');
  background-position: calc(30px * (-4) - 20px) calc(30px * 0 - 20px);
}
.icons-setting {
  display: inline-block;
  width: 15px;
  height: 16px;
  background: url('/assets/images/icon.png');
  background-position: calc(30px * (-5) - 20px) calc(30px * 0 - 20px);
}

/* ag-grid */
.ag-theme-balham .ag-header-row {
  min-height: 36px !important;
}
.ag-theme-balham .ag-row {
  min-height: 36px !important;
}
.ag-theme-balham .ag-cell, .ag-theme-balham .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  color: #636363;
  overflow-x: hidden;
  /*line-height: 32px !important;*/
}
.ag-body-viewport.ag-layout-normal {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ag-body-viewport.ag-layout-normal::-webkit-scrollbar {
   display: none; /* Chrome, Safari, Opera*/
}

.ag-cell.center {
  justify-content: center;
}
.ag-cell.right {
  justify-content: right;
}


/* modal */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .modal-wrap {
    height: auto;
    max-height: 100%;
    transform: translate(0);
    background-color: #fff;
    border-radius: 8px;
    .modal-header {
      height: 60px;
      background-color: #fff;
      border-bottom: 1px solid #e9ecef;
      border-radius: 8px 8px 0 0;
      padding: 0 20px;
      .modal-title {
        float: left;
        font-size: 18px;
        font-weight: 500;
        color: #636363;
        line-height: 60px;
      }
      .close-btn {
        float: right;
        width: 24px;
        min-width: 24px;
        height: 24px;
        padding: 0;
        margin-top: 18px;
        font-size: 28px;
        color: #636363;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
        .MuiSvgIcon-root {
          font-size: 28px;
        }
      }
    }
  }
}
.modal-wrap .modal-body {
  padding: 10px;
}
.modal-wrap .modal-footer {
  padding: 10px 20px;
  border-top: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  text-align: right;
}

.modal-wrap1 .modal-body label {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.modal-wrap .modal-body label .TextField-input.w100 {
  width: calc(100% - 104px);
}
.modal-wrap .modal-body label .TextField-input.MuiFormControl-root .MuiOutlinedInput-root {
  width: 100%;
}

/* tab */
.Tab-page {
  height: 100%;

  .MuiTabs-flexContainer {
    background-color: #ddd;
  }

  .MuiTab-root.Mui-selected {
    background-color: #555;
    color: #fff;
  }

  .MuiTabs-indicator {
    height: 5px;
    background-color: #46a1fa;
  }

  .tab-menu {
    width: 100%;
    height: 36px;
    border-bottom: 1px solid #0d88c8;

    li {
      float: left;
      font-size: 14px;
      font-weight: 500;
      color: #949494;
      line-height: 32px;
      text-align: center;
      height: 35px;
      padding: 0 20px;
      background: #e6e6e6;
      cursor: pointer;

      &.active {
        color: #fff;
        background: #0d88c8;
      }
    }

    &.menu2 {
      li {
        width: calc(100% / 2);
      }
    }
    &.menu4 {
      li {
        width: calc(100% / 4);
      }
    }
    &.menu5 {
      li {
        width: calc(100% / 5);
      }
    }
    &.menu7 {
      li {
        width: calc(100% / 7);
      }
    }
  }
  .tab-contents {
    height: calc(100% - 35px);
    // padding: 20px 0 0;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    > div {
      height: 100%;
    }
  }
  .tab-contents::-webkit-scrollbar {
     display: none; /* Chrome, Safari, Opera*/
  }
}

/* table */
.table-input {
  margin: 15px 0;
  th {
    font-size: 14px;
    font-weight: 500;
    color: #636363;
    text-align: right;
    padding-right: 10px;
    background-color: #f2f2f2;
    border: 1px solid #dee2e6;

    &.minHeight39 {
      min-height: 39px;
      height: 39px;
    }
  }
  td {
    font-size: 12px;
    font-weight: 500;
    color: #636363;
    line-height: 28px;
    padding: 5px;
    border: 1px solid #dee2e6;
  }
  .TextField-input.MuiTextField-root {
    width: 100%;
    font-size: 12px;
  }
}

/* table */
.input-table {
  margin: 15px 0;
  th {
    font-size: 14px;
    font-weight: 500;
    color: #636363;
    text-align: right;
    padding-right: 10px;
    background-color: #f2f2f2;
    border: 1px solid #dee2e6;
  }
  td {
    font-size: 12px;
    font-weight: 500;
    color: #636363;
    line-height: 28px;
    padding: 5px;
    border: 1px solid #dee2e6;
  }
  .TextField-input {
    width: 100%;
    font-size: 12px;
  }
}

.lh150 {
  line-height: 150% !important;
}
.lh22px {
  line-height: 22px;
}
.lh28px {
  line-height: 28px !important;
}
.lh35px {
  line-height: 35px !important;
}
.lh38px {
  line-height: 38px;
}
.lh40px {
  line-height: 40px !important;
}
.lh45px {
  line-height: 45px;
}

.search-toggle-btn {
  height: 29px !important;
  line-height: 29px !important;
  padding: 0px !important;
  font-size: 13px !important;
  vertical-align: bottom !important;
  font-weight: normal !important;

  &.Mui-selected {
    font-weight: bold !important;
  }
}

.search-input-btn {
  input {
    height: 29px !important;
    line-height: 29px !important;
    padding: 0px 8px !important;
    font-size: 14px !important;
  }

  .MuiInputLabel-root {

    font-size: 14px !important;
  }
}

.input-date {
  input {
    height: 40px !important;
    line-height: 34px !important;
    padding: 0px 10px !important;
    font-size: 14px !important;
  }

  .MuiInputLabel-root {

    font-size: 14px !important;
  }
}


/* homepage Modal */
.home-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .modal-wrap {
    max-width: 90%;
    height: auto;
    max-height: 100%;
    transform: translate(0);
    background-color: #fff;
    border-radius: 10px;
  }
}
.ck-voice-label {
  display: none !important;
}
.ck-editor__editable_inline {
  height: calc(100vh - 480px);
}

.MuiCheckbox-root {
  zoom: 1.5;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex {
  display: flex;
}

.flex-center {
  align-items: center;
  justify-items: center;
}

.flex-low {
  align-items: flex-end;
}

.top-line {
  border-top: solid 2px #888 !important;
}