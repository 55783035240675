/* main home */
.main-home {
  .mobile-only {display: none;}
  .quick-menu1 {
    padding: 50px 0 20px;
    li {
      float: left;
      width: calc((100% - 60px) / 4);
      height: 420px;
      border-radius: 10px;
      margin-right: 20px;
      text-align: center;
      transition: all .4s ease;

      .link-item {
        display: block;
        height: 420px;
        border-radius: 10px;

        .title {
          font-size: 34px;
          font-weight: 500;
          color: #222;
          letter-spacing: -3px;
          padding: 205px 0 16px;
        }
        .txt {
          font-size: 13px;
          font-weight: 500;
          color: #777;
          letter-spacing: -1px;
          line-height: 20px;
          padding-bottom: 27px;
        }
        .link-btn {
          width: 100px;
          height: 34px;
          border-radius: 17px;
          background: #fff;
          font-size: 13px;
          font-weight: bold;
          color: #222;
          letter-spacing: -1px;
        }
        &:hover {
          text-decoration: none;
        }
      }
      &:hover {
        filter: drop-shadow(0 0 12px rgba(66,66,66,0.3));
        // box-shadow: 18px 18px 32px 0 rgba(0, 0, 0, 27%);
      }
      &:nth-last-child(1) {margin-right: 0;}
      &:nth-child(1) .link-item {
        background: #eff0f3 url('/assets/images/home/main/quickmenu1_01.png') no-repeat 50% 67px;
      }
      &:nth-child(2) .link-item {
        background: #eff0f3 url('/assets/images/home/main/quickmenu1_02.png') no-repeat 50% 67px;
      }
      &:nth-child(3) .link-item {
        background: #eff0f3 url('/assets/images/home/main/quickmenu1_03.png') no-repeat 50% 67px;
      }
      &:nth-child(4) .link-item {
        background: #eff0f3 url('/assets/images/home/main/quickmenu1_04.png') no-repeat 50% 67px;
      }
    }
  }

  .quick-menu2 {
    padding-bottom: 20px;
    .link-btn {
      float: left;
      display:flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: calc((100% - 40px) / 3);
      height: 90px;
      border-radius: 10px;
      border: 1px solid #ebebeb;
      .btn-title {
        font-size: 22px;
        letter-spacing: -2px;
        padding-right: 34px;
        background: url('/assets/images/home/main/icon_link.png') no-repeat 100% 50%;
      }
      &:hover {
        text-decoration: none;
      }
      &:nth-child(2) {
        margin: 0 20px;
      }
    }
  }

  .quick-menu3 {
    .link-btn {
      display:flex;
      justify-content: center;
      align-items: center;
      height: 220px;
      background: #e5f1fc url('/assets/images/home/main/quickmenu3_01.png') no-repeat calc(50% + 50px) 50%;
      border-radius: 10px;
      text-align: center;

      .title {
        font-size: 34px;
        font-weight: 500;
        color: #222;
        letter-spacing: -3px;
        padding-bottom: 20px;
      }
      .txt {
        font-size: 16px;
        font-weight: 500;
        color: #777;
        letter-spacing: -1px;
        line-height: 24px;
      }
      &:hover {
        text-decoration: none;
        .title {
          color: #38589f;
        }
      }
    }
  }

  .notice {
    padding: 90px 0;
    .notice-fl {
      float: left;
      width: calc(100% - 955px);
      .notice-title {
        font-size: 34px;
        font-weight: 500;
        letter-spacing: -3px;
      }
      .guide-txt {
        font-size: 13px;
        font-weight: 500;
        color: #777;
        letter-spacing: -1px;
        line-height: 20px;
        padding: 110px 0 37px;
        background: url('/assets/images/home/main/icon_notice.png') no-repeat 0 30px;
      }
      .more {
        font-size: 13px;
        font-weight: 500;
        color: #222;
        letter-spacing: -1px;
        padding-right: 35px;
        background: url('/assets/images/home/main/icon_arrow.png') no-repeat 100% 50%;

        &:hover {
          font-size: 15px;
          text-decoration: none;
        }
      }
    }
    .notice-list {
      float: left;
      width: 955px;
      ul {
        border-top: 4px solid #222;
        li {
          height: 100px;
          border-bottom : 1px solid #eee;
          .txt {
            float: left;
            font-size: 28px;
            font-weight: 500;
            letter-spacing: -2px;
            line-height: 100px;
            max-width: calc(100% - 100px);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .date {
            float: right;
            font-size: 13px;
            font-weight: 400;
            color: #777;
            line-height: 100px;
          }
          .contents {
            margin-top: 10px;
          }
          &:hover {
            .txt {
              color: #38589f;
            }
          }
        }
      }
    }
  }
  .quick-menu4 {
    padding: 70px 0;
    background-color: #f5f6f8;
    li {
      float: left;
      width: calc((100% - 68px) / 3);
      height: 160px;
      border-radius: 10px;
      filter: drop-shadow(0 0 12px rgba(66,66,66,0.3));
      transition: all .4s ease;

      .link-item {
        display: block;
        height: 160px;
        padding: 45px 0 0 206px;

        .title {
          font-size: 22px;
          color: #222;
          padding-bottom: 10px;
        }
        .txt {
          font-size: 13px;
          font-weight: 400;
          color: #777;
          letter-spacing: -1px;
          line-height: 20px;
        }
        &:hover {
          text-decoration: none;
        }
      }
      &:hover {
        // filter: drop-shadow(0 0 12px rgba(66,66,66,0.7));
        filter: drop-shadow(12px 12px 24px rgba(66,66,66,0.7));
      }
      &:nth-child(1) {
        background: #fff url('/assets/images/home/main/quickmenu4_01.png') no-repeat -1px 0;
      }
      &:nth-child(2) {
        margin: 0 34px;
        background: #fff url('/assets/images/home/main/quickmenu4_02.png') no-repeat -1px 0;
      }
      &:nth-child(3) {
        background: #fff url('/assets/images/home/main/quickmenu4_03.png') no-repeat -1px 0;
      }
    }
  }
}

@media screen and (max-width:1300px) {
  .main-home {
    .quick-menu1 {
      padding: 25px 25px 0px;
      li {
        float: left;
        width: calc(50% - 50px);
        height: 420px;
        margin: 25px;
      }
    }

    .quick-menu2 {
      padding: 0 50px 20px;
    }

    .notice {
      padding: 90px 50px;
      .notice-fl {
        float: none;
        width: 100%;
        height: 50px;
        .notice-title {
          float: left;
        }
        .guide-txt {
          display: none;
        }
        .more {
          float: right;
          padding: 20px 0 0;
          background: none;
        }
      }
      .notice-list {
        float: none;
        width: 100%;
      }
    }
    .quick-menu4 {
      padding: 70px 50px;
      li {
        float: none;
        width: 100%;

        &:nth-child(2) {
          margin: 30px 0;
        }
      }
    }
  }
}

@media screen and (max-width:750px) {
  .main-home {
    .pc-only {display: none;}
    .mobile-only {display: block;}
    .quick-menu1 {
      padding: 6rem 0 0;
      li {
        float: left;
        width: calc((100% - 4rem) / 2);
        height: 40rem;
        border-radius: 1rem;
        margin: 0 4rem 4rem 0;
        &:nth-child(2n) {
          margin: 0 0 4rem 0;
        }
        .link-item {
          display: block;
          height: 40rem;
          border-radius: 1rem;

          .title {
            font-size: 3.4rem;
            font-weight: 500;
            letter-spacing: -3px;
            padding: 20.5rem 0 4rem;
          }
          .txt {
            display: none;
          }
          .link-btn {
            width: 15rem;
            height: 4.8rem;
            border-radius: 2.4rem;
            background: #fff;
            font-size: 2.4rem;
            font-weight: 500;
            letter-spacing: -1px;
          }
        }
        &:nth-child(1) .link-item {
          background: #eff0f3 url('/assets/images/home/main/quickmenu1_01.png') no-repeat 50% 6.7rem /12rem auto;
        }
        &:nth-child(2) .link-item {
          background: #eff0f3 url('/assets/images/home/main/quickmenu1_02.png') no-repeat 50% 6.7rem /12rem auto;
        }
        &:nth-child(3) .link-item {
          background: #eff0f3 url('/assets/images/home/main/quickmenu1_03.png') no-repeat 50% 6.7rem /12rem auto;
        }
        &:nth-child(4) .link-item {
          background: #eff0f3 url('/assets/images/home/main/quickmenu1_04.png') no-repeat 50% 6.7rem /12rem auto;
        }
      }
    }

    .quick-menu2 {
      padding: 0 0 4rem;
      .link-btn {
        float: none;
        width: 100%;
        height: 9rem;
        border-radius: 1rem;
        border: 1px solid #ebebeb;
        .btn-title {
          font-size: 3rem;
          letter-spacing: -2px;
          padding-right: 5rem;
          background: url('/assets/images/home/main/icon_linkm.png') no-repeat 100% 50% / 3.2rem auto;
        }
        &:nth-child(2) {
          margin: 2rem 0;
        }
      }
    }

    .quick-menu3 {
      margin: 0 4.8rem;
      .link-btn {
        display:block;
        height: 38rem;
        background: #e5f1fc url('/assets/images/home/main/quickmenu3_01m.png') no-repeat calc(50% + 3rem) 6rem / 19.2rem auto;
        border-radius: 1rem;
        text-align: center;

        .title {
          font-size: 3.4rem;
          font-weight: 500;
          letter-spacing: -3px;
          padding: 18.5rem 0 2.5rem;
        }
        .txt {
          font-size: 2.3rem;
          font-weight: 500;
          color: #777;
          letter-spacing: -2px;
          line-height: 3.2rem;
        }
      }
    }

    .notice {
      padding: 10rem 0;
      .notice-fl {
        float: none;
        width: 100%;
        height: 6.5rem;
        .notice-title {
          font-size: 3.4rem;
          font-weight: 500;
          letter-spacing: -2px;
        }
        .guide-txt {
          display: none;
        }
        .more {
          float: right;
          font-size: 2.4rem;
          font-weight: 500;
          letter-spacing: -1px;
          padding: 1rem 0 0;

          &:hover {
            font-size: 2.4rem;
            text-decoration: none;
          }
        }
      }
      .notice-list {
        float: none;
        width: 100%;
        ul {
          border-top: 0.4rem solid #222;
          li {
            height: auto;
            padding: 5.2rem 0;
            border-bottom : 1px solid #eee;
            .txt {
              float: none;
              font-size: 2.8rem;
              font-weight: 500;
              letter-spacing: -1px;
              line-height: 1;
              max-width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .date {
              float: none;
              font-size: 2.2rem;
              font-weight: 400;
              color: #777;
              line-height: 1;
              padding-top: 1.6rem;
            }
            &:hover {
              .txt {
                color: #38589f;
              }
            }
          }
        }
      }
    }
    .quick-menu4 {
      padding: 4.5rem 0;
      background-color: #f5f6f8;
      li {
        float: none;
        width: 100%;
        height: 16rem;
        border-radius: 1rem;
        background-size: contain !important;

        .link-item {
          display: block;
          height: 16rem;
          padding: 4.5rem 0 0 20rem;

          .title {
            font-size: 3rem;
            padding-bottom: 2rem;
          }
          .txt {
            font-size: 2.2rem;
            font-weight: 400;
            color: #777;
            letter-spacing: -1px;
            line-height: 2.8rem;
          }
        }
        &:nth-child(1) {
          background: #fff url('/assets/images/home/main/quickmenu4_01.png') no-repeat -1px 0;
        }
        &:nth-child(2) {
          margin: 4rem 0;
          background: #fff url('/assets/images/home/main/quickmenu4_02.png') no-repeat -1px 0;
        }
        &:nth-child(3) {
          .link-item {
            padding: 3rem 0 0 20rem;
          }
          background: #fff url('/assets/images/home/main/quickmenu4_03.png') no-repeat -1px 0;
        }
      }
    }
  }
}