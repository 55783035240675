.Toastify__toast {
  padding: 0px;
  background-color: #272b32 !important;
  box-shadow: 3px 3px 5px rgb(50 50 50 / 40%);
}

.Toastify__toast-container--top-right,
.Toastify__toast-container--bottom-right,
.Toastify__toast-container--bottom-left {
  width: 505px;
}

.Toastify__toast-body {
  padding: 0px;
  width: 505px;
  background-color:#272b32;
  box-shadow: -4px 0px 7px rgb(50 50 50 / 50%);
}

.toastify-title {
  float:left;
  width: 85px;
  height: 100%;
  text-align: center;
  min-height: 85px;
  line-height: 130%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 18px;
  background-color: #272b32;
}

.Toastify__toast-icon {
  display:none;
}

.toastify-error { background-color: #d60000; color: #fff; }
.toastify-warning { background-color: #ffa800; color: #fff; }
.toastify-information { background-color: #009cff; color: #fff; }
.toastify-primary { background-color: #004edc; color: #fff; }
.toastify-success { background-color: #008009; color: #fff; }
.toastify-darkgray { background-color: #6d6d6d; }
.toastify-lightgray { color:#333; background-color: #e8ebf2; }
.toastify-black { background-color: #1c1e1f; color: #fff; }

.toastify-message {
  width: 395px;
  height: 100%;
  max-height: 85px;
  min-height: 85px;
  line-height: 150%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 20px;
  float: left;
  font-weight: 300;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 18px;
  color:#fff;
}

.Toastify__close-button {
  display: inline-block;
  position: relative;
  right: 0.5em;
  top: 0.5em;
  color:#fff;
}