.sub-header-home {
  padding: 100px 0;

  .page-title {
    font-size: 48px;
    font-weight: 500;
    color: #333c4d;
    letter-spacing: -4px;
    text-align: center;
  }
  .path {
    width: max-content;
    margin: 30px auto 0;
    li {
      float: left;
      font-size: 14px;
      &:not(:last-child):after {
        content: ">";
        padding: 0 10px;
      }
      .home {
        padding-left: 15px;
        background: url('/assets/images/home/subheader/icon_home.png') no-repeat 0 50%;
      }
    }
  }
}

@media screen and (max-width:750px) {
  .sub-header-home {
    padding: 10rem 0;

    .page-title {
      font-size: 4.8rem;
      font-weight: 500;
      color: #333c4d;
      letter-spacing: -0.4rem;
      text-align: center;
    }
    .path {
      display: none;
    }
  }  
}  