.contents-body-admin {
  min-width: 1920px;
  height: 100%;
  background: #dce1e8;

  .contents {
    width: 100%;
    height: 100vh;
    padding: 80px 20px 20px 250px;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .contents::-webkit-scrollbar {
     display: none; /* Chrome, Safari, Opera*/
  }

  /* page header */
  .page-header {
    height: 50px;

    .page-title {
      position: relative;
      float: left;
      font-size: 20px;
      font-weight: 500;
      color: #132235;
      line-height: 45px;

      &:before {
        position: absolute;
        content: '';
        width: 40px;
        height: 3px;
        background-color: #343b3f;
      }
    }
  }
  /* page contents */
  .page-contents {
    min-height: calc(100vh - 150px);
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
  }
  .contents-header {
    .Btn.sm .MuiSvgIcon-root {
      font-size: 20px;
      color: #737d8a;
    }
    .right-area {
      float: right;
      .Btn {float: left;}
    }
    .label {
      font-size: 12px;
      font-weight: 500;
      color: #636363;
      line-height: 28px;
    }
  }
}


/* Header admin */
.header-company {
  position: fixed;
  width: 100%;
  height: 60px;
  background: #fff;
  border-bottom: 1px solid #e9edee;
  z-index: 9;

  .logo {
    float: left;
    display:flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 100%;
    img {
      height: 27px;
    }
  }
  .menu-btn {
    height: 60px;
  }

  .util-area {
    float: right;

    .top-icon {
      display: inline-block;
      width: 59px;

      .right-icon {
        font-size: 25px;
        color: #606060;
        height: 60px;
        line-height: 60px;
      }
    }
    .login-info {
      display: inline-block;
      width: 60px;
      height: 60px;
      padding-top: 10px;
      vertical-align: bottom;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

  }
}

/* SNB admin */
.snb-admin {
  position: fixed;
  left: 0;
  top: 60px;
  width: 230px;
  height: calc(100vh - 60px);
  background-color: #f0f2f5;
  z-index: 9;

  .snbmenu {
    height: 100%;
    padding: 20px;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
     display: none; /* Chrome, Safari, Opera*/
    }

    .menu {
      height: 40px;
      cursor: pointer;
      .depth1:hover {background-color: #ccc;}
      .title {
        float: left;
        font-size: 14px;
        font-weight: 500;
        color: #606061;
        line-height: 40px;
      }
      .icon1 {
        float: left;
        width: 38px;
        height: 40px;
        line-height: 40px;
        text-align: center;
      }
      .icon2 {
        float: right;
        width: 18px;
        height: 18px;
        padding: 11px 0 0;
        .MuiSvgIcon-root {
          font-size: 18px;
          color: #737d8a;
        }
      }
      &:hover {
        .title {color: #383838;}
      }
      &.open {
        height: auto;
        .title {color: #383838;}
        .icon2 {
          transform: rotate(90deg);
          transition: .2s;
          padding: 0 0 0 11px;
        }
        .submenu {
          height: auto;
          transition: height .5s ease;

          li:hover {
            background-color: #ccc;
          }
        }
      }
    }
    .submenu {
      height: 0;
      overflow: hidden;
      transition: height .5s ease;
      background-color: #dce1e8;

      li {
        height: 40px;

        .subtitle {
          display: block;
          font-size: 13px;
          font-weight: 500;
          color: #606061;
          line-height: 40px;
          padding-left: 30px;
          .icon {
            float: left;
            width: 14px;
            height: 14px;
            padding-right: 20px;
            .MuiSvgIcon-root {
              font-size: 10px;
              color: #737d8a;
            }
          }
        }
      }
    }
  }
}

.contents-body-admin .contents.reduce {
  padding: 80px 20px 20px 90px;
}
.contents-body-admin .contents.reduce .snb-admin {
  width: 70px;

  .snbmenu {
    height: 100%;
    padding: 20px 10px;

    .menu {
      height: 40px;
      cursor: pointer;
      .depth1:hover {background-color: #ccc;}
      .title {
        display: none;
        margin-left: 10px;
        position: fixed;
        left: 74px;
        width: 180px;
        z-index: 99999;
        background-color: #f0f2f5;
        padding: 0px 0px 0px 30px;
        margin: 0px;
        box-shadow: 4px 4px 5px #737d8a;
        opacity: 100;
        transition: opacity 0.3s ease;
      }
      .icon1 {
        width: 30px;
      }
      &.open {
        .title {
          display: block;
        }
      }
    }
    .submenu {
      position: fixed;
      left: 74px;
      width: 180px;
      z-index: 99999;
      background-color: #f0f2f5;
      padding: 0;
      margin: 0;
      display: inline-block;
      box-shadow: 4px 4px 5px #737d8a;
      opacity: 100;
      transition: opacity 0.3s ease;
    }
  }
}

/* 자격증발급신청 상세보기 */
.certCardImgBox {
  position: relative;
  height: 280px;
  background: url('/assets/images/admin/card_sample01.jpg') no-repeat 0 0;

  .memberPic {
    position: absolute;
    width: 100px;
    height: 133px;
    top: 97px;
    left: 35px;
    img {
      width: 100px;
      height: 133px;
    }
  }
}
