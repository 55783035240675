.Login-admin {
  position: fixed;
  top: 60px;
  display:flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 160px);
  width: 100vw;
  background: #dce1e8;

  .login-wrap {
    width: 340px;
  }
  .login-logo {
    height: 50px;
    background: url('/assets/images/admin/logo.png') no-repeat 50%;
    margin: 0 auto 20px;
  }
  .inputbox {
    .MuiFormControl-root {
      font-size: 16px;
      flex-direction: initial;
      margin-bottom: 10px;

      .MuiOutlinedInput-root {
        width: 340px;
        font-size: 16px;
        color: #636363;
        background-color: #fff !important;
        border: 1px solid #fff;
        border-radius: 25px;
        height: 50px;

        input {
          height: 48px;
          padding: 0 20px !important;
        }
      }
      .MuiOutlinedInput-root fieldset {
        border: 0px solid #415282;
      }
      .MuiOutlinedInput-root:hover fieldset {
        border: 2px solid #415282;
      }
      .MuiOutlinedInput-root.Mui-focused fieldset {
        border: 2px solid #415282;
      }

      label.MuiInputLabel-formControl {
        font-size: 15px !important;
      }
    }
  }

  .login-btn {
    font-size: 16px;
    color: #fff;
    width: 340px;
    height: 50px;
    border-radius: 25px !important;
    background-color: #415282 !important;
    margin: 20px 0;
    .MuiButton-label {
      color: inherit;
    }
  }

  /* CheckBox */
  .Checkbox.MuiFormControlLabel-root {
    height: 28px;

    .MuiSvgIcon-root {
      width: 21px;
      height: 21px;
      background: #dce1e8;
      border: none;
    }
    .MuiCheckbox-root {
      color: #000;
    }
    .MuiTypography-body1 {
      font-size: 16px;
    }
  }
}

.footer-admin {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  background-color: #dce1e8;
  border-top: 1px solid #c1c8d1;


  .footer-txt {
    font-size: 13px;
    color: #606061;
    text-align: center;
    margin-top:30px;
  }
}