 /* header home */
.header-home {
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 9;

  .header1 {
    height: 70px;
    border-bottom: 1px solid #ececec;
    .logo {
      display: flex;
      align-items: center;
      height: 70px;
      float: left;
    }
    .util-menu {
      height: 70px;
      float: right;
      text-align: right;
      .user-menu {
        float: left;
        > li {
          float: left;
          .usermenu {
            font-size: 14px;
            font-weight: 500;
            color: #222;
            line-height: 70px;
          }
        }
        > li + li:before {
          display: inline-block;
          content: '';
          width: 1px;
          height: 10px;
          background-color: #cfcfcf;
          margin: 0px 20px;
          vertical-align: middle;
        }
      }
    }
  }

  .header2 {
    height: 68px;
    border-bottom: 1px solid #ececec;
    .gnb {
      position: relative;
      float: left;
      width: calc(100% - 130px);

      .menu {
        display: flex;
        justify-content: space-between;

        > li {
          //float: left;
          //padding-right: 100px;
          .depth1 {
            font-size: 20px;
            font-weight: 500;
            color: #222;
            line-height: 68px;
            &:hover {
              color: #38589f;
              text-decoration: none;
            }
          }
          &:hover {
            .submenu-wrap {
              display: block;
            }
          }
          .submenu-btn {
            display: none;
          }
        }
        .submenu-wrap {
          display: none;
          position: fixed;
          top: 138px;
          left: 0;
          right: 0;
          background-color: #38589f;
          padding-left: calc(50% - 420px);
          .menu-title {
            position: absolute;
            top: 40px;
            left: calc(50% - 640px);
            font-size: 30px;
            color: #e2e9f0;
            letter-spacing: -1px;
          }
          .submenu {
            width: 100%;
            padding: 20px 0 130px;
            background-color: #fff;
            border-bottom: 1px solid #ececec;
            > li {
              padding: 20px 40px;
              border-bottom: 1px solid #ececec;
              .depth2 {
                float: left;
                font-size: 18px;
                font-weight: 500;
                color: #222;
                width: 220px;
              }
              .submenu-wrap2 {
                float: left;
                .submenu2 {
                  > li {
                    float: left;
                    .depth3 {
                      font-size: 16px;
                      font-weight: 500;
                      color: #777;
                      padding-right: 50px;
                      &:hover {
                        color: #38589f;
                      }
                    }
                  }
                }
              }
              &:nth-last-child(1) {
                border-bottom: none;
              }

            }
          }
        }
        & > li:nth-child(n+8) {display: none;}
      }
    }
    .tot-menu {
      float: right;
      width: 24px;
      min-width: 24px;
      height: 19px;
      margin-top: 24px;
      background: url('/assets/images/home/header/icon_totmenu.png');
      border-radius: 0;
    }
    .close-menu {
      display: none;
      position: absolute;
      top: 60px;
      left: calc(50% - 30px);
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #222 url('/assets/images/home/header/icon_close.png') no-repeat 50%;
    }
  }
}

/* 전체메뉴 */
.header-home.open {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #eff0f3;
  overflow: auto;
  z-index: 99;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
     display: none; /* Chrome, Safari, Opera*/
  }

  .header1 {display: none;}
  .header2 {
    height: 100vh;
    padding: 200px 30px 100px;
    border: none;
    .tot-menu {display: none;}
    .close-menu {display: block;}

    .gnb {
      position: relative;
      float: none;
      .menu {
        display: block;
        > li {
          float: left;
          width: 25%;
          padding: 0;
          .depth1 {
            display: block;
            font-size: 30px;
            font-weight: bold;
            line-height: 1;
            padding-bottom: 30px;
            border-bottom: 1px solid #222;
          }
        }
        .submenu-wrap {
          display: block;
          position: inherit;
          background-color: transparent;
          padding-left: 0;
          .menu-title {
            display: none;
          }
          .submenu {
            width: 100%;
            height: 410px;
            padding: 0;
            background-color: transparent;
            border-bottom: none;
            > li {
              padding: 0;
              border-bottom: none;
              .depth2 {
                display: block;
                float: none;
                font-size: 16px;
                font-weight: 500;
                width: 100%;
                padding-top: 28px;
              }
              .submenu-wrap2 {
                float: none;
                .submenu2 {
                  > li {
                    float: none;
                    .depth3 {
                      display: block;
                      font-size: 14px;
                      font-weight: 500;
                      color: #777;
                      padding: 17px 0 0 10px;
                      &:hover {
                        color: #38589f;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        & > li:nth-child(n+8) {display: block;}
      }
    }
  }
}

@media screen and (max-width:1350px) {
  .header-home {
    .header1 {
      .util-menu {
        position: absolute;
        top: 68px;
        right: 50px;
      }
    }
    .header2 {
      .tot-menu {
        position: absolute;
        top: 0;
        right: 50px;
      }
    }
    .gnb {
      display: none;
    }
  }
  .header-home.open {
    .header2 {
      .gnb {
        display: block;
        .menu {
          > li {
            width: calc(100% / 3);
          }
        }
      }
    }
  }
}

@media screen and (max-width:750px) {
  .header-home {
    .header1 {
      height: 11.2rem;
      padding: 0;
      .logo {
        height: 11.2rem;
        img {
          height: 4.4rem;
        }
      }
      .util-menu {display: none;}
    }

    .header2 {
      height: 0;

      .tot-menu {
        position: absolute;
        top: 3.8rem;
        right: 4.8rem;
        width: 4.8rem;
        min-width: 4.8rem;
        height: 3.6rem;
        margin-top: 0;
        background: url('/assets/images/home/header/icon_totmenum.png') no-repeat 50% / 4.8rem auto;
      }
      .close-menu {
        display: none;
        position: absolute;
        top: 3.8rem;
        right: 4.8rem;
        left: auto;
        width: 4.5rem;
        min-width: 4.5rem;
        height: 4.5rem;
        border-radius: 0;
        background: url('/assets/images/home/header/icon_closem.png') no-repeat 50% / 4.5rem;
      }
    }
  }

  /* 전체메뉴 */
  .header-home.open {
    position: fixed;
    width: 100%;
    height: 100vh;
    // background-color: #eff0f3;
    overflow: hidden;
    z-index: 99;

    .header1 {
      display: block;
      height: 11.2rem;
      padding: 0;
      background: #fff;
      border-bottom: 1px solid #ececec;
      .logo {
        // display: flex;
        // align-items: center;
        height: 11.2rem;
        // float: left;
        img {
          height: 4.4rem;
        }
      }
      .util-menu {
        position: absolute;
        top: 11.2rem;
        left: 0;
        z-index: 9;
        display: block;
        width: 100%;
        height: 10.5rem;
        padding: 0 4.8rem;
        background: #fff;
        float: right;
        text-align: right;
        .user-menu {
          float: left;
          > li {
            float: left;
            .usermenu {
              font-size: 2.8rem;
              font-weight: 500;
              line-height: 10.5rem;
              padding-right: 8rem;
            }
          }
          > li + li:before {
            display: none;
          }
        }
      }
    }
    .header2 {
      height: calc(100vh - 11.2rem);
      padding: 10.5rem 0 0;
      border: none;
      overflow-y: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
         display: none; /* Chrome, Safari, Opera*/
      }
      .tot-menu {display: none;}
      .close-menu {display: block;}

      .inner {padding: 0;}
      .gnb {
        position: relative;
        float: none;
        .menu {
          width: 30rem;
          height: auto;
          min-height: calc(100vh - 21.7rem);
          padding: 4rem 0;
          background: #38589f;
          > li {
            float: none;
            width: 100%;
            padding: 0;
            .depth1 {
              display: block;
              font-size: 4.4rem;
              font-weight: 500;
              color: #fff;
              letter-spacing: -2px;
              line-height: 13rem;
              padding: 0 0 0 4.8rem;
              border-bottom: none;
            }
            &:hover {
              background: #eff0f3;
              .depth1 {
                color: #222;
              }
            }
          }
          .submenu-wrap {
            display: none;
            position: absolute;
            top: 0;
            left: 30rem;
            width: calc(100vw - 33rem);
            background-color: transparent;
            padding: 4rem 0 0 0;
            .menu-title {
              display: none;
            }
            .submenu {
              width: 100%;
              height: auto;
              padding: 0 0 0 4.8rem;
              background-color: transparent;
              border-bottom: none;
              > li {
                padding: 0;
                border-bottom: none;
                .depth2 {
                  display: block;
                  float: none;
                  font-size: 3.4rem;
                  font-weight: 500;
                  letter-spacing: -2px;
                  line-height: 7.4rem;
                  width: 100%;
                  padding-top: 2rem;
                }
                .submenu-wrap2 {
                  float: none;
                  .submenu2 {
                    > li {
                      float: none;
                      .depth3 {
                        display: block;
                        font-size: 2.8rem;
                        font-weight: 500;
                        color: #777;
                        letter-spacing: -1px;
                        line-height: 6.8rem;
                        padding: 0 0 0 2rem;
                        &:hover {
                          color: #38589f;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          & > li:nth-child(n+8) {display: block;}
        }
      }
    }
  }
}