.join-home {
  padding: 100px 0 150px;
  .sub-header {
    padding-bottom: 20px;
    border-bottom: 1px solid #e3e7eb;
    .page-title {
      font-size: 48px;
      font-weight: 500;
      color: #333c4d;
      letter-spacing: -4px;
      text-align: center;
    }
    .path {
      float: right;
      width: max-content;
      margin: 30px 0 0;
      li {
        float: left;
        font-size: 16px;
        color: #777;
        &.active {
          color: #222;
          font-weight: 500;
        }
        &:not(:last-child):after {
          content: ">";
          padding: 0 20px;
        }
      }
    }
  }
  
  .join-step1 {
    .terms {
      max-height: 300px;
      padding: 30px;
      border: 1px solid #d3d5db;
      overflow-y: scroll;
      font-size: 14px;
      color: #777;
      letter-spacing: -1px;
      line-height: 24px;
      margin-top: 15px;
    }

    .Checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f6f7;
      height: 55px;
      margin-top: 20px;
      &.MuiFormControlLabel-root .MuiTypography-body1 {
        font-size: 14px;
        font-weight: 500;
        color: #222;
      }
    }

    .guide-txt {
      font-size: 13px;
      font-weight: 500;
      color: #666;
      letter-spacing: -1px;
      line-height: 26px;
      padding-top: 15px;
    }
  
    .terms1 {
      font-size: 17px;
      color: #222;
      letter-spacing: -1px;
      line-height: 26px;
      text-align: center;
      padding: 70px 0;
      overflow: hidden;
      .HBtn {
        font-size: 18px;
        width: 300px;
        height: 55px;
        font-size: 18px !important;
        margin: 25px 0 0;
        &.MuiButton-root {
          border-radius: 0;
        }
      }
    }
  }

  .join-complete {
    .complete-div {
      background: url('/assets/images/home/contents/icon_join.png') no-repeat 50% 125px;
      .txt {
        font-size: 48px;
        font-weight: bold;
        letter-spacing: -2px;
        padding: 290px 0 40px;
        text-align: center;
      }
      .HBtn {
        display: flex;
        width: 260px;
        height: 55px;
        font-size: 18px;
        margin: 0 auto;
        &.MuiButton-root {
          border-radius: 0;
        }
      } 
    }
  }

  
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #9facd8;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #9facd8;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #9facd8;
  }
  
  .join-step2 {
  
    .step2-wrap {
      width: 645px;
      padding-top: 60px;
      margin: 0 auto;

      table {
        text-align: left;
        th {
          color: #222;
          font-weight: 500;          
          vertical-align: top;
          padding-top: 25px;
        }
        td {
          padding: 5px 0;

          &.addr {
            .zip {
              width: calc(100% - 140px);
            }
            .HBtn {
              width: 135px;
              height: 55px;
              font-size: 14px !important;
              margin-left: 5px;
            }
            .addr1 {
              margin: 10px 0;
            }
          }
          
          .input {
            .MuiInputBase-root {
              height: 55px;
              padding: 0 10px;
              .MuiInputBase-input{
                font-size: 14px !important;
              }
            }
            &.disabled {
              background: #eee;
            }
            .MuiButtonBase-root {
              height: 55px;
              padding: 0 20px;
            }
          }
        }
      }
      .HBtn.join-btn {
        width: 500px;
        height: 55px;
        font-size: 18px !important;
        margin: 15px 0 0 145px;
      }
    }
  }
}  

 
@media screen and (max-width:750px) {
  .join-home {
    padding: 10rem 0;
    .sub-header {
      padding-bottom: 3rem;
      margin: 0 -4.8rem;
      border-bottom: 1px solid #e3e7eb;
      .page-title {
        font-size: 4.8rem;
        font-weight: 500;
        color: #333c4d;
        letter-spacing: -4px;
        text-align: center;
      }
      .path {
        float: none;
        width: max-content;
        margin: 10rem auto 0;
        li {
          float: left;
          font-size: 2.8rem;
          color: #777;
          &.active {
            color: #222;
            font-weight: 500;
          }
          &:not(:last-child):after {
            content: ">";
            padding: 0 4rem;
          }
        }
      }
    }
    
    .join-step1 {
      .txt {
        margin-bottom: 3.5rem;
      }
      .terms {
        max-height: 47.5rem;
        padding: 3.5rem 4rem;
        border: 1px solid #d3d5db;
        overflow-y: scroll;
        font-size: 2.4rem;
        color: #777;
        letter-spacing: -1px;
        line-height: 3.4rem;
        margin-top: 0;
      }

      .Checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5f6f7;
        height: auto;
        padding: 3rem 0;
        margin: 4rem 0 0;
        &.MuiFormControlLabel-root .MuiTypography-body1 {
          font-size: 2.4rem;
          font-weight: 500;
          color: #222;
        }
        .MuiSvgIcon-root {
          width: 3rem;
          height: 3rem;
        }
      }

      .guide-txt {
        font-size: 2.4rem;
        font-weight: 500;
        color: #666;
        letter-spacing: -1px;
        line-height: 3.4rem;
        padding-top: 3.5rem;
      }
    
      .terms1 {
        font-size: 2.8rem;
        color: #222;
        letter-spacing: -1px;
        line-height: 4rem;
        text-align: center;
        padding: 0;
        overflow: hidden;
        border: none;
        > div {
          font-size: 2.8rem;
          color: #222;
          letter-spacing: -1px;
          line-height: 4rem;
          padding: 4rem 3rem;
          border: 1px solid #d3d5db;
        }
        .HBtn {
          font-size: 2.8rem;
          width: 100%;
          height: 9rem;
          margin: 4rem 0 0;
          &.MuiButton-root {
            border-radius: 0;
          }
        }
      }
    }

    .join-complete {
      .complete-div {
        background: url('/assets/images/home/contents/icon_join.png') no-repeat 50% 20rem / 18.6rem auto;
        .txt {
          font-size: 4.8rem;
          font-weight: bold;
          letter-spacing: -2px;
          padding: 38rem 0 15rem;
          text-align: center;
        }
        .HBtn {
          display: flex;
          width: 100%;
          height: 9rem;
          font-size: 2.8rem;
          margin: 0 auto 5rem;
          &.MuiButton-root {
            border-radius: 0;
          }
        } 
      }
    }

    
    /* width */
    ::-webkit-scrollbar {
      width: 0.5rem;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #9facd8;
      border-radius: 1rem;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #9facd8;
      border-radius: 1rem;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #9facd8;
    }
    
    .join-step2 {
    
      .step2-wrap {
        width: 100%;
        padding-top: 6rem;
        margin: 0 auto;

        table {
          text-align: left;
          tr {
            display: block;
            width: calc(100vw - 9.6rem);
          }
          th {
            display: block;
            font-size: 2.4rem;
            letter-spacing: -1px;
            padding-top: 3rem;
          }
          td {
            display: block;
            padding: 1rem 0;

            .input {
              height: 9rem;
              input {
                font-size: 2.4rem;
                height: 9rem;
                padding: 0 3rem;
              }
            }

            &.addr {
              .zip {
                width: calc(100% - 20rem);
              }
              .HBtn {
                width: 19rem;
                height: 9rem;
                margin-left: 1rem;
              }
              .addr1 {
                margin: 2rem 0;
              }
            }
          }
        }
        .HBtn.join-btn {
          width: 100%;
          height: 9rem;
          font-size: 2.8rem;
          margin: 4rem 0 10rem;
        }
      }
    }
  }  
}  