.contents-body-home {
  background-color: #fff;
  .mobile-only {display: none;}
  .contents {
    padding-top: 138px;
  }
  .inner {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }
  .guide {
    padding-bottom: 140px;
    .mobile-title {
      display: none;
    }
    .h3 {
      font-size: 20px;
      font-weight: bold;
      color: #38589f;
      letter-spacing: -1px;
      padding-top: 80px;
      padding-bottom: 15px;
    }
    .txt {
      font-size: 16px;
      font-weight: 500;
      color: #666;
      letter-spacing: -1px;
      line-height: 26px;
      p {
        font-size: 16px;
        font-weight: 500;
        color: #666;
        letter-spacing: -1px;
        line-height: 26px;
        margin-bottom: 10px;
        &.f14 {font-size: 14px;}
        &.icon-bank {
          font-size: 17px;
          font-weight: bold;
          color: #222;
          padding-left: 20px;
          background: url('/assets/images/home/contents/icon_bank.png') no-repeat 0 50%;
        }
        &.icon-caution {
          color: #fa4700;
          padding-left: 40px;
          background: url('/assets/images/home/contents/icon_caution.png') no-repeat 20px 50%;
        }
        &.icon-caution1 {
          color: #fa4700;
          padding: 10px 0 0;
        }
      }
      .li-title {
        padding-right: 20px;
      }
      &.txt1 {
        font-size: 14px;
        p {
          font-size: 14px;
        }
      }
      &.txt2 {
        font-size: 14px;
      }
    }
    .cred {
      color: #fa4700 !important;
    }
    .cbule {
      color: #38589f !important;
    }
    .info3-img {
      max-width: 100%;
      &.mobile {display: none;}
    }
    .guide-box {
      font-size: 16px;
      font-weight: 500;
      color: #666;
      letter-spacing: -1px;
      line-height: 24px;
      padding: 35px 40px;
      background: #f5f6f8;
    }
    .info-box {
      text-align: center;
      padding: 65px 0;
      border: 1px solid #ededed;
      margin-top: 25px;
      .info1 {
        font-size: 20px;
        color: #666;
        letter-spacing: -1px;
        padding-bottom: 30px;
      }
      .info2 {
        font-size: 34px;
        color: #666;
        letter-spacing: -2px;
      }
    }
    .info-box1 {
      text-align: center;
      padding: 70px 0;
      border: 1px solid #ededed;
      .info1 {
        font-size: 30px;
        color: #666;
        letter-spacing: -1px;
        padding-bottom: 20px;
      }
      .info2 {
        font-size: 20px;
        color: #666;
        letter-spacing: -1px;
      }
      .btn-area {
        margin-top: 30px;
        .HBtn {
          width: 190px;
          height: 40px;
        }
      }
    }
    .info-box2 {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -1px;
      text-align: center;
      padding: 65px 0;
      background: #f5f6f7;
      margin-top: 40px;
    }
    .pass-box {
      text-align: center;
      padding: 190px 0 90px;
      border: 1px solid #ededed;
      background: url('/assets/images/home/contents/icon_pass.png') no-repeat 50% 90px;
      margin-top: 25px;
      .txt1 {
        font-size: 28px;
        font-weight: bold;
        letter-spacing: -1px;
        padding-bottom: 30px;
        // .cred {color: #ee0000;}
      }
      .txt2 {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -1px;
      }
    }
    .agree-box {
      font-size: 14px;
      font-weight: 500;
      color: #666;
      letter-spacing: -1px;
      line-height: 26px;
      text-align: left;
      padding: 40px 35px;
      border: 1px solid #ededed;
      margin-bottom: 20px;
    }
    .agree-check {
      display:flex;
      justify-content: center;
      align-items: center;
      height: 55px;
      background: #f5f6f7;
      .Checkbox.MuiFormControlLabel-root .MuiTypography-body1 {
        font-size: 14px;
      }
    }
    .certificate-samp {
      border: 1px solid #ddd;
      margin: 10px 0 20px;
      li {
        float: left;
        width: 50%;
        text-align: center;
        padding: 50px 0;
        border-right: 1px solid #ededed;
        &:nth-child(1) {
          img {
            height: 260px;
          }
        }
        img {
          max-width: 95%;
        }
        .HBtn {
          display: block;
          width: 130px;
          height: 40px;
          margin: 25px auto 0;
        }
        &:nth-last-child(1) {
          padding: 65px 0 50px;

        }
      }
    }

    .basic-btn {
      font-size: 14px;
      font-weight: 500;
      color: #666;
      letter-spacing: -1px;
      height: 40px;
      padding: 0 20px;
      border-radius: 5px;
      border: 1px solid #ebebeb;
    }
    .step-ul {
      width: 100%;
      li.step {
        float: left;
        display:flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: calc((100% - 140px) / 6);
        height: 100px;
        background-color: #f5f6f8;
        border: 1px solid #e3e7eb;
        border-radius: 12px;
        .no {
          font-size: 14px;
          font-weight: 500;
          color: #3676ef;
          margin-bottom: 10px;
        }
        .step-title {
          font-size: 18px;
          font-weight: 500;
          color: #333c4d;
          letter-spacing: -1px;
        }
      }
      li.between {
        float: left;
        width: 28px;
        height: 100px;
        background: url('/assets/images/home/contents/icon_step.png') no-repeat 50%;
      }
    }

    .select-box {
      display:flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -1px;
      padding: 55px;
      background: #f5f6f8;
      .guide-txt {
        margin-bottom: 30px;
      }
      .select-div {
        > span {
          color: #666;
          line-height: 40px;
        }
        .selectBox {
          width: 490px;
          height: 42px;
          background: #fff;
          margin: 0 10px 0 20px;
          .MuiInputBase-root .MuiInputBase-input {
            font-size: 16px;
            height: 41px !important;
            border-radius: 0;
          }
        }
        .selected {
          display: inline-block;
          width: 490px;
          height: 42px;
          line-height: 40px;
          text-align: left;
          padding-left: 15px;
          border: 1px solid #ddd;
          background: #fff;
          margin: 0 0 0 20px;
          .MuiOutlinedInput-input {
            height: 42px;
            padding: 0 15px;
          }
        }
        .HBtn {
          height: 42px;
          padding: 0 20px;
          vertical-align: bottom;
        }
      }
    }
  }
  .list-table {
    tr {
      th,td {
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        text-align: center;
        height: 55px;
        border: 1px solid #e3e7eb;
      }
      th {
        background: #f5f6f7;
      }
      td {
        color: #666;
      }
      th:nth-child(1),td:nth-child(1) {
        border-left: none;
      }
      th:nth-last-child(1),td:nth-last-child(1) {
        border-right: none;
      }
    }
    &.tr-sm {
      th, td {height: 45px;}
    }
    &.td-wide {
      td {
        padding: 40px 0;
      }
    }
    &.td-left {
      td {
        text-align: left;
        padding: 0 10px;
      }
    }
    .input {
      height: 38px;
      border-radius: 0;
      .MuiOutlinedInput-root {
        font-size: 14px;
        border-radius: 0;
      }
      .MuiOutlinedInput-input {
        height: 38px !important;
        padding: 0 10px;
      }
    }
    .unit-input {
      max-width: calc(100% - 25px);
    }
    .unit {
      padding-left: 5px;
      padding-right: 5px;
      vertical-align: middle;
    }
    .Checkbox {
      .MuiTypography-body1 {
        font-size: 14px;
      }
      &.cred.MuiFormControlLabel-root .MuiTypography-body1 {
        color: #fa4700;
      }
    }
    .filebox {
      .input {
        width: 340px;
      }
      .basic-btn {
        display: inline-block;
        line-height: 32px;
        height: 38px;
        border-radius: 0;
        border: 1px solid #ccc;
        margin-left: 5px;
        vertical-align: middle;
      }
    }
    .radio {
      .MuiTypography-body1 {
        font-size: 14px;
      }
    }
    .info-txt {
      .txt1 {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -1px;
        padding: 30px 0 15px;
      }
      .txt2 {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -1px;
        line-height: 24px;
        padding-bottom: 25px;
      }
    }
    .addr {
      .input {
        max-width: 100%;
        width: 690px;
        height: 33px;
        .MuiOutlinedInput-input {
          height: 33px;
        }
      }
      .input.zip {width: 100px;}
      .HBtn {
        font-size: 14px;
        height: 33px;
        margin-left: 5px;
      }
    }
  }
  .member-table {
    table-layout: fixed;
    .pic {
      padding-left: 0 !important;
      border: none;
      padding-right: 20px;
      .pic-div {
        display:flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: #f5f6f8;
        img {
          width: 100px;
          height: 133px;
          background: #fff;
        }
      }
    }
    th {
      border-left: none !important;
    }
    td.tl {
      text-align: left;
      padding-left: 10px;
    }
  }
  .btn-area {
    position: relative;
    margin-top: 80px;
    border: none;
  }

  /* tab home */
  .Tab-page-home {
    .tab-menu {
      display: flex;
      width: 100%;
      height: 75px;
      li {
        flex: 1;
        float: left;
        font-size: 22px;
        font-weight: bold;
        color: #949599;
        letter-spacing: -1px;
        line-height: 70px;
        text-align: center;
        height: 75px;
        border-top: 4px solid #f5f6f8;
        border-bottom: 1px solid #333c4d;
        background: #f5f6f8;
        cursor: pointer;

        &.active {
          color: #333c4d;
          background: #fff;
          border: 1px solid #333c4d;
          border-top: 4px solid #333c4d;
          border-bottom: none;
        }
      }
    }
    .tab-contents {
      padding: 40px 0 0;
    }
  }

  /* btn */
  .HBtn {
    font-size: 16px;
    font-weight: 500;
    color: #222;
    border: 1px solid #d3d7dc;
    border-radius: 0;
    &.lg {
      width: 260px;
      height: 54px;
    }
    &.bluebg {
      color: #fff;
      background-color: #38589f;
      border: 1px solid #38589f;
    }
    &.blackbg {
      color: #fff;
      background-color: #333c4d;
      border: 1px solid #333c4d;
    }
    &.graybg {
      color: #777;
      background-color: #d3d5db;
      border: 1px solid #d3d5db;
    }
    span {
      color: inherit;
    }
  }
}

/* modal */
.modal-wrap.home {
  border-radius: 0;
  .modal-header {
    height: 45px;
    background: #38589f;
    .modal-title {
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      letter-spacing: -1px;
      line-height: 45px;
    }
    .close-btn {
      margin-top: 12px;
      .MuiSvgIcon-root {
        path {color:#fff;}
      }
    }
  }
  .modal-body {
    padding: 20px;

    .h3 {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -1px;
    }
    .btn-area {
      position: relative;
      border: none;
      height: auto;
      padding: 0;
      margin: 40px 0 20px;
    }

    /* refundInfo */
    &.refundInfo {
      .infoTxt {
        font-size: 20px;
        font-weight: bold;
        color: #222;
        line-height: 1.5;
        text-align: center;
      }
      .infoBox {
        font-size: 16px;
        font-weight: 500;
        color: #e30000;
        line-height: 1.8;
        letter-spacing: -1px;
        padding: 25px 40px;
        background: #f8f8f8;
        border-radius: 12px;
        margin: 30px auto 20px;
      }
      .infoTxt1 {
        display: flex;
        font-size: 15px;
        color: #222;
        letter-spacing: -1px;
        line-height: 1.5;
        text-align: left;
        padding-bottom: 10px;
        .txt {
          padding-left: 3px;
        }
      }
    }
  }
}

.application-print {
  .info1-table {
    th, td {
      height: 32px;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      border: 1px solid #adadad;
    }
    td {
      padding-left: 10px;
      &.juminno {
        padding-left: 1px;
        td {
          height: 25px;
          padding: 0;
          text-align: center;
        }
      }
      &.addr {
        height: 45px;
      }
      &.info-txt {
        padding: 10px 50px;
        &.pl10 {
          padding: 10px;
        }
        .date {
          text-align: center;
          padding: 15px 0 0;
        }
        .name {
          text-align: center;
          padding: 15px 0 0;
        }
        .com {
          font-weight: bold;
          padding: 15px 0 0;
        }
      }
      &.pic {
        padding: 3px 0;
        text-align: center;
        img {
          width: 111px;
          height: 144px;
        }
      }
    }
  }
  .divide {
    height: 3px;
    border-top: 1px solid #adadad;
    border-bottom: 1px solid #adadad;
    margin: 14px 0;
  }
}

@media screen and (max-width:1350px) {
  .contents-body-home {
    .inner {
      padding: 0 50px;
    }
    .guide {
      padding-bottom: 140px;
    }
    .Tab-page-home {
      .tab-menu {
        li {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width:750px) {
  html {font-size: 1.333vw;} /* 10px */

  .contents-body-home {
    .pc-only {display: none;}
    .mobile-only {display: block;}
    .contents {
      padding-top: 11.2rem;
    }
    .inner {
      padding: 0 4.8rem;
    }
    .guide {
      padding-bottom: 9rem;
      .mobile-title {
        display: block;
        font-size: 4rem;
        font-weight: bold;
        letter-spacing: -2px;
        padding: 10rem 0;
        text-align: center;
      }
      .h3 {
        font-size: 3rem;
        font-weight: bold;
        color: #38589f;
        letter-spacing: -1px;
        padding-top: 9rem;
        padding-bottom: 3.5rem;
      }
      .txt {
        font-size: 2.8rem;
        font-weight: 500;
        color: #666;
        letter-spacing: -1px;
        line-height: 4rem;
        p {
          font-size: 2.8rem;
          font-weight: 500;
          color: #666;
          letter-spacing: -1px;
          line-height: 4rem;
          margin-bottom: 2rem;
          &.f14 {font-size: 2.4rem;}
          &.icon-bank {
            font-size: 2.8rem;
            font-weight: bold;
            color: #222;
            padding-left: 4rem;
            background: url('/assets/images/home/contents/icon_bank.png') no-repeat 0 50% / 2.8rem auto;
          }
          &.icon-caution {
            color: #fa4700;
            padding-left: 6rem;
            background: url('/assets/images/home/contents/icon_caution.png') no-repeat 2.5rem 50% / 2.5rem auto;
          }
          &.icon-caution1 {
            color: #fa4700;
            padding: 1rem 0 0;
          }
        }
        .li-title {
          padding-right: 4rem;
        }
        &.txt1 {
          font-size: 2.2rem;
          line-height: 3.8rem;
          p {
            font-size: 2.2rem;
          }
        }
        &.txt2 {
          font-size: 2.4rem;
          text-align: left !important;
          padding-bottom: 2rem;
        }
      }
      .info3-img {
        max-width: 100%;
        &.pc {display: none;}
        &.mobile {display: block;}
      }
      .guide-box {
        font-size: 2.4rem;
        font-weight: 500;
        color: #666;
        letter-spacing: -1px;
        line-height: 3.8rem;
        padding: 3.5rem 4rem;
        background: #f5f6f8;
      }
      .info-box {
        text-align: center;
        padding: 5rem 3rem;
        border: 1px solid #ededed;
        margin-top: 3rem;
        .info1 {
          font-size: 3rem;
          color: #666;
          line-height: 1.5;
          letter-spacing: -1px;
          padding-bottom: 5rem;
        }
        .info2 {
          font-size: 4rem;
          color: #666;
          letter-spacing: -2px;
        }
      }
      .info-box1 {
        text-align: center;
        padding: 5rem 3rem;
        border: 1px solid #ededed;
        .info1 {
          font-size: 3rem;
          color: #666;
          letter-spacing: -1px;
          padding-bottom: 5rem;
        }
        .info2 {
          font-size: 2rem;
          color: #666;
          letter-spacing: -1px;
        }
        .btn-area {
          margin-top: 6rem;
          .HBtn {
            width: 100%;
            height: 9rem;
          }
        }
      }
      .line-x {
        height: 1px;
        margin: 2rem -4.8rem 0;
        border-bottom: 1px solid #ececec
      }
      .info-box2 {
        font-size: 2.8REM;
        font-weight: 500;
        letter-spacing: -1px;
        text-align: center;
        padding: 6rem 0;
        background: #f5f6f7;
        margin: 6rem 0 4rem;
      }
      .pass-box {
        text-align: center;
        padding: 17rem 0 6rem;
        border: 1px solid #ededed;
        background: url('/assets/images/home/contents/icon_pass.png') no-repeat 50% 6rem / 8.9rem auto;
        margin-top: 3rem;
        .txt1 {
          font-size: 3.4rem;
          font-weight: bold;
          letter-spacing: -1px;
          line-height: 4.8rem;
          padding-bottom: 3.5rem;
          // .cred {color: #ee0000;}
        }
        .txt2 {
          font-size: 2.4rem;
          font-weight: 500;
          letter-spacing: -1px;
        }
      }
      .agree-box {
        font-size: 2.4rem;
        font-weight: 500;
        color: #666;
        letter-spacing: -1px;
        line-height: 4rem;
        text-align: left;
        padding: 4rem 3.5rem;
        border: 1px solid #ededed;
        margin-bottom: 4rem;
      }
      .agree-check {
        display:flex;
        justify-content: center;
        align-items: center;
        height: 9rem;
        background: #f5f6f7;
        .Checkbox.MuiFormControlLabel-root .MuiTypography-body1 {
          font-size: 2.4rem;
        }
      }
      .certificate-samp {
        border: 1px solid #ddd;
        margin: 0 0 5rem;
        li {
          float: none;
          width: 100%;
          text-align: center;
          padding: 8rem 0;
          border-right: none;
          .HBtn {
            display: block;
            width: calc(100% - 12rem);
            height: 9rem;
            margin: 4rem auto 0;
          }
          &:nth-last-child(1) {
            padding: 8rem 0;
          }
          &:nth-child(1) {
            border-bottom: 1px solid #ddd;
            img {
              width: 49rem;
            }
          }
          &:nth-child(2) {
            img {
              width: 13.9rem;
            }
          }
        }
      }

      .basic-btn {
        font-size: 2.4rem;
        font-weight: 500;
        color: #666;
        letter-spacing: -1px;
        height: 8rem;
        padding: 0 4rem;
        border-radius: 5px;
        border: 1px solid #ebebeb;
      }
      .step-ul {
        width: 100%;
        li.step {
          float: none;
          display:flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 100%;
          height: 8rem;
          background-color: #f5f6f8;
          border: 1px solid #e3e7eb;
          border-radius: 1.2rem;
          .no {
            float: left;
            font-size: 2.8rem;
            font-weight: 500;
            color: #3676ef;
            line-height: 8rem;
            margin: 0 2rem 0 0;
          }
          .step-title {
            float: left;
            font-size: 2.8rem;
            font-weight: 500;
            color: #333c4d;
            line-height: 8rem;
            letter-spacing: -1px;
          }
        }
        li.between {
          float: none;
          width: 100%;
          height: 7.5rem;
          background: url('/assets/images/home/contents/icon_stepm.png') no-repeat 50% / 3rem auto;
        }
      }

      .select-box {
        display:block;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 2.6rem;
        padding: 0;
        background: #fff;
        .guide-txt {
          margin-bottom: 2rem;
        }
        .select-div {
          .selectBox {
            width: 100%;
            height: 9rem;
            background: #fff;
            margin: 0;
            .MuiInputBase-root .MuiInputBase-input {
              font-size: 2.8rem;
              height: 9rem;
              line-height: 1;
              border-radius: 0;
            }
          }
          .selected {
            display: inline-block;
            width: 100%;
            height: 9rem;
            line-height: 9rem;
            text-align: left;
            padding-left: 2rem;
            border: 1px solid #ddd;
            background: #fff;
            margin: 0;
            .MuiOutlinedInput-input {
              height: 9rem;
              padding: 0 2rem;
            }
          }
          .HBtn {
            height: 42px;
            padding: 0 20px;
            vertical-align: baseline;
          }
        }
      }
    }
    .table-wrap {
      width: 100%;
      overflow-x: auto;
    }
    .list-table {
      width: 200vw;
      margin-bottom: 4rem !important;
      tr {
        th,td {
          font-size: 2.8rem;
          font-weight: 500;
          line-height: 3rem;
          text-align: center;
          height: 8.5rem;
          border: 1px solid #e3e7eb;
        }
        th:nth-child(3) {
          margin-top: 6rem;
        }
      }
      &.tr-sm {
        th, td {height: 6rem;}
      }
      &.td-wide {
        td {
          padding: 4rem 0;
        }
      }
      &.td-left {
        td {
          text-align: left;
          padding: 0 1rem;
        }
      }
      .input {
        height: 9rem;
        border-radius: 0;
        .MuiOutlinedInput-root {
          border-radius: 0;
        }
        .MuiOutlinedInput-input {
          height: 9rem;
          padding: 0 2rem;
          font-size: 2.8rem;
        }
      }
      .unit {
        padding-left: 5px;
        vertical-align: middle;
      }
      .Checkbox {
        .MuiTypography-body1 {
          font-size: 2.4rem;
        }
        &.cred.MuiFormControlLabel-root .MuiTypography-body1 {
          color: #fa4700;
        }
      }
      .filebox {
        .input {
          display: none;
        }
        .basic-btn {
          display: inline-block;
          font-size: 2.6rem;
          line-height: 9rem;
          text-align: center;
          width: 100%;
          height: 9rem;
          border-radius: 0;
          background: #fff;
          border: 1px solid #ccc;
          margin: 1rem 0 0;
          vertical-align: middle;
        }
      }
      .radio {
        .MuiFormControlLabel-root {
          margin-right: 10rem;
        }
        .MuiTypography-body1 {
          font-size: 2.8rem;
        }
        .MuiSvgIcon-root {
          width: 3rem;
          height: 3rem;
        }
      }
      .info-txt {
        margin-bottom: 4rem;
        .txt1 {
          font-size: 3rem;
          font-weight: 500;
          letter-spacing: -1px;
          padding: 2rem 0 2rem;
        }
        .txt2 {
          font-size: 2.8rem;
          font-weight: 500;
          letter-spacing: -1px;
          line-height: 4rem;
          padding-bottom: 1.5rem;
        }
      }
      .addr {
        .input {
          width: 100%;
          height: 9rem;
          background: #fff;
          .MuiOutlinedInput-input {
            height: 9rem;
          }
        }
        .input.zip {width: 34rem;}
        .HBtn {
          width: calc(100% - 35rem);
          font-size: 2.4rem;
          height: 9rem;
          margin-left: 1rem;
          background: #fff;
        }
      }
    }
    .member-table {
      width: 100%;
      margin-bottom: 0 !important;
      tbody {
        display: block;
        width: calc(100vw - 9.6rem);
        padding-bottom: 3rem;
        background: #f5f6f7;
      }
      tr {
        display: block;
        width: calc(100vw - 9.6rem);
        padding: 3rem 4rem;
        background: #f5f6f7;
        th {
          display: block;
          font-size: 2.2rem;
          text-align: left;
          border: none;
          padding: 0 0 2rem 0 !important;
          height: auto !important;
        }
        td {
          display: block;
          font-size: 2.8rem;
          text-align: left;
          border: none;
          padding: 0 !important;
          height: auto !important;
        }
      }
      .pic {
        margin: 0 -4rem;
        border-bottom: 2rem solid #fff;
        .pic-div {
          display:flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 2rem 0 5rem;
          background: #f5f6f8;
          img {
            width: 19.5rem;
            height: 26rem;
            background: #fff;
          }
        }
      }
      .pic + th {
        padding-top: 6rem !important;
      }
      .addr-tr {
        .addr {
          margin-top: -5rem;
        }
      }
    }
    .member-table1 {
      tbody {padding: 3rem 0;}
      tr {
        padding: 0;
        th {
          padding: 3rem 4rem 2rem !important;
        }
        td {
          padding: 0 4rem 3rem !important;
        }
      }
    }
    .file-table {
      width: 100%;
      margin-bottom: 0 !important;
      tr {
        display: block;
        width: calc(100vw - 9.6rem);
        th {
          display: block;
          font-size: 2.2rem;
          text-align: left;
          border: none;
          padding: 0 0 1rem 0 !important;
          height: auto;
          background: #fff;
        }
        td {
          display: block;
          font-size: 2.8rem;
          text-align: left;
          border: none;
          padding: 4rem !important;
          height: auto;
          background: #f5f6f7;
          .Checkbox {
            padding-bottom: 4rem;
          }
          p {font-size: 2.4rem;}
        }
      }
    }
    .payment-table {
      width: 100%;
      margin-bottom: 0 !important;
      tr {
        display: block;
        width: calc(100vw - 9.6rem);
        th {
          display: none;
        }
        td {
          display: block;
          font-size: 2.8rem;
          text-align: left;
          border: none;
          padding: 4rem !important;
          height: auto;
          background: #f5f6f7;
          .Checkbox {
            padding-bottom: 4rem;
          }
          p {font-size: 2.4rem;}
        }
      }
    }
    .payment-table1 {
      width: 100%;
      margin-bottom: 0 !important;
      tr {
        display: block;
        width: calc(100vw - 9.6rem);
        th {
          display: block;
          font-size: 2.2rem;
          text-align: left;
          border: none;
          padding: 0 !important;
          height: auto;
          background: #fff;
        }
        td {
          display: block;
          font-size: 2.8rem;
          letter-spacing: -1px;
          text-align: left;
          border: none;
          padding: 0 !important;
          margin: 2rem 0 4rem !important;
          height: auto;
          background: #fff;
          .input {
            width: 100%;
            height: 9rem;
            .MuiOutlinedInput-input {
              height: 9rem;
            }
          }
          .unit-input {
            width: calc(100% - 5rem);
          }
          .unit {
            display: inline-block;
            padding-top: 2.5rem;
          }
        }
      }
      tbody {
        > tr:nth-child(n+2) {
          td {
            padding: 4rem !important;
            background: #f5f6f7;
          }
        }
      }
    }
    .btn-area {
      margin-top: 2rem;
    }

    .application-list-table {
      li {
        padding: 4.8rem;
        background: #f5f6f7;
        border: 1px solid #d3d7dc;
        margin-bottom: 2rem;
      }
      .exam {
        .th {
          float: none;
          line-height: 1;
          padding-bottom: 2rem;
        }
        .td {
          float: none;
          line-height: 1;
          padding-bottom: 4rem;
          border-bottom: 1px solid #dfe1e4;
          margin-bottom: 2rem;
        }
      }
      .th {
        float: left;
        font-size: 2.2rem;
        line-height: 6.5rem;
        width: 18rem;
      }
      .td {
        float: left;
        font-size: 2.8rem;
        line-height: 6.5rem;
      }
    }

    /* tab home */
    .Tab-page-home {
      .tab-menu {
        display: block;
        width: calc(100% + 9.6rem);
        height: auto;
        margin: 0 -4.8rem;
        li {
          flex: 1;
          float: left;
          width: calc(50% - 0.5rem);
          height: 10rem;
          font-size: 2.8rem;
          font-weight: bold;
          color: #949599;
          letter-spacing: -1px;
          line-height: 10rem;
          text-align: center;
          height: 10rem;
          border: 1px solid #ebebeb;
          background: #f5f6f8;
          margin-bottom: 1rem;

          &:nth-child(2n) {
            margin-left: 1rem;
          }
          &.active {
            color: #fff;
            background: #fff;
            background: #333c4d;
            border: 1px solid #333c4d;
          }
        }
        &:after {
          content: "";
          display: block;
          clear: both;
        }
      }
      .tab-contents {
        padding: 0;
      }
    }

    /* btn */
    .HBtn {
      font-size: 2.8rem;
      font-weight: 500;
      border: 1px solid #d3d7dc;
      border-radius: 0;
      &.lg {
        width: 100%;
        height: 9rem;
      }
    }
  }

  /* modal */
  .modal-wrap.home {
    max-width: 95vw;
    .modal-header {
      height: 9rem;
      padding: 0 4rem;
      background: #38589f;
      .modal-title {
        font-size: 3.8rem;
        font-weight: 500;
        color: #fff;
        letter-spacing: -1px;
        line-height: 9rem;
      }
      .close-btn {
        margin-top: 2.2rem;
        font-size: 4.8rem;
        .MuiSvgIcon-root {
          font-size: 4.8rem;
          path {color:#fff;}
        }
      }
    }
    .modal-body {
      max-height: calc(100vh - 9rem);
      overflow-y: auto;
      padding: 4rem;

      .h3 {
        font-size: 2.4rem;
        font-weight: bold;
        letter-spacing: -1px;
        margin: 4rem 0 2rem;
        &.mt0 {
          margin-top: 0;
        }
      }
      .btn-area {
        margin: 4rem 0 2rem;
      }
      .full-img {
        max-width: 100%;
      }
      .list-table {
        tbody {
          width: calc(100vw - 8rem);
          tr {
            > th:nth-child(3) {
              padding-top: 6rem !important;
            }
          }
        }
      }

      /* refundInfo */
      &.refundInfo {
        .infoTxt {
          font-size: 3.5rem;
        }
        .infoBox {
          font-size: 2.7rem;
          padding: 3rem;
          margin: 2rem auto;
        }
      }
    }
  }
}