@font-face {
  font-family: 'o2oz';
  src:  url('fonts/o2oz.eot?4txige');
  src:  url('fonts/o2oz.eot?4txige#iefix') format('embedded-opentype'),
    url('fonts/o2oz.ttf?4txige') format('truetype'),
    url('fonts/o2oz.woff?4txige') format('woff'),
    url('fonts/o2oz.svg?4txige#o2oz') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-first"], [class*=" ico-first"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'o2oz' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-firstquick:before {
  content: "\e924";
}
.ico-firstdelete:before {
  content: "\e925";
}
.ico-firstmb_menu:before {
  content: "\e921";
}
.ico-firstlogout:before {
  content: "\e923";
}
.ico-firstmb_cart:before {
  content: "\e922";
}
.ico-firstmb_menu06:before {
  content: "\e91b";
}
.ico-firstmb_menu05:before {
  content: "\e91c";
}
.ico-firstmb_menu04:before {
  content: "\e91d";
}
.ico-firstmb_menu03:before {
  content: "\e91e";
}
.ico-firstmb_menu02:before {
  content: "\e91f";
}
.ico-firstmb_menu01:before {
  content: "\e920";
}
.ico-firstsave:before {
  content: "\e914";
}
.ico-firstnote_add:before {
  content: "\e915";
}
.ico-firstmood:before {
  content: "\e916";
}
.ico-firstsentiment_satisfied:before {
  content: "\e917";
}
.ico-firstsentiment_dissatisfied:before {
  content: "\e918";
}
.ico-firstmood_bad:before {
  content: "\e919";
}
.ico-firstsentiment_very_dissatisfied:before {
  content: "\e91a";
}
.ico-firstfiber_new:before {
  content: "\e90d";
}
.ico-firstinsert_chart:before {
  content: "\e90e";
}
.ico-firstmonetization_on:before {
  content: "\e90f";
}
.ico-firstlocal_shipping:before {
  content: "\e910";
}
.ico-firstshopping_basket:before {
  content: "\e911";
}
.ico-firstweekend:before {
  content: "\e912";
}
.ico-firstassignment:before {
  content: "\e913";
}
.ico-firstorder:before {
  content: "\e907";
}
.ico-firstcancel:before {
  content: "\e908";
}
.ico-firstest:before {
  content: "\e909";
}
.ico-firstremote:before {
  content: "\e90a";
  color: #606060;
}
.ico-firstoto:before {
  content: "\e90b";
}
.ico-firstmn01:before {
  content: "\e900";
}
.ico-firstmn02:before {
  content: "\e901";
}
.ico-firstmn03:before {
  content: "\e902";
}
.ico-firstmn04:before {
  content: "\e903";
}
.ico-firstmn05:before {
  content: "\e904";
}
.ico-firstmn06:before {
  content: "\e905";
}
.ico-firstas:before {
  content: "\e90c";
}
.ico-firstclose:before {
  content: "\e906";
}
