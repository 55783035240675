.findIdPw {
  padding-bottom: 150px;
  .page-title {
    font-size: 48px;
    font-weight: 500;
    color: #333c4d;
    letter-spacing: -4px;
    text-align: center;
    padding: 100px 0 70px;
  }

  .find-div {
    float: left;
    width: calc(50% - 20px);
    height: 500px;
    padding: 75px 135px 0;
    background: #eff0f3;
    border-radius: 10px;

    &:nth-child(1) {
      margin-right: 40px;
    }  
    .find-title {
      font-size: 34px;
      color: #333c4d;
      letter-spacing: -3px;
      text-align: center;
      padding-bottom: 45px;
    }
    .input {
      font-size: 16px;
      background: #fff;
      margin-bottom: 10px;

      .MuiInputBase-root {
        height: 55px;
        padding: 0 10px;
        .MuiInputBase-input{
          font-size: 14px !important;
        }
      }
    }  

    .HBtn.blackbg {
      font-size: 18px !important;
      height: 56px;
      margin-top: 10px;
      border-radius: 4px;
    }
  }
  .result-div {
    padding: 105px 0 110px;
    background: #eff0f3;
    border-radius: 10px;
    .result-wrap {
      width: 350px;
      margin: 0 auto;
    }

    .find-title {
      font-size: 34px;
      color: #333c4d;
      letter-spacing: -3px;
      text-align: center;
      padding-bottom: 45px;
    }
    .id {
      font-size: 16px;
      letter-spacing: -1px;
      line-height: 45px;
      text-align: center;
      border-bottom: 1px solid #d3d3d3;
      margin-bottom: 50px;
    }
    .input {
      font-size: 16px;
      background: #fff;
      margin-bottom: 10px;

      .MuiInputBase-root {
        height: 55px;
        padding: 0 10px;
        .MuiInputBase-input{
          font-size: 14px !important;
        }
      }
    }  
  
    .HBtn.blackbg {
      font-size: 18px !important;
      height: 56px;
      margin-top: 10px;
      border-radius: 4px;
    }
  }
}  

@media screen and (max-width:1350px) {
  .findIdPw {
    .find-div {
      float: none;
      width: 620px;
      height: auto;
      padding: 75px 135px;
      background: #eff0f3;
      border-radius: 10px;
      margin: 0 auto 100px;
  
      &:nth-child(1) {
        margin: 0 auto 30px;
      }  
    }
  }    
}  

@media screen and (max-width:750px) {
  .findIdPw {
    padding: 0 4.8rem 13rem !important;
    .page-title {
      font-size: 4.8rem;
      font-weight: 500;
      color: #333c4d;
      letter-spacing: -4px;
      text-align: center;
      padding: 10rem 0;
    }
  
    .find-div {
      float: none;
      width: 100%;
      height: auto;
      padding: 7.5rem 2.6rem;
      background: #eff0f3;
      border-radius: 1rem;
      margin: 0 0 2rem;
  
      &:nth-child(1) {
        margin-right: 0;
      }  
      .find-title {
        font-size: 3.8rem;
        color: #333c4d;
        letter-spacing: -3px;
        text-align: center;
        padding-bottom: 4.5rem;
      }
      .input {
        font-size: 2.8rem;
        height: 9rem;
        background: #fff;
        margin-bottom: 2rem;
        border-radius: 1rem;;
        .MuiOutlinedInput-root {
          border-radius: 1rem;;
        }
        .MuiInputBase-input {
          font-size: 2.8rem;
          height: 9rem;
          padding: 0 4rem;          
        }
      }
    
      .HBtn.blackbg {
        font-size: 2.8rem;
        height: 9rem;
        margin-top: 0;
        border-radius: 1rem;
      }
    }
    .result-div {
      padding: 7.5rem 2.5rem 13rem;
      background: #eff0f3;
      border-radius: 1rem;
      .result-wrap {
        width: 100%;
        margin: 0 auto;
      }
  
      .find-title {
        font-size: 3.8rem;
        color: #333c4d;
        letter-spacing: -3px;
        text-align: center;
        padding-bottom: 8rem;
      }
      .id {
        font-size: 2.8rem;
        letter-spacing: -1px;
        line-height: 6.8rem;
        text-align: center;
        border-bottom: 1px solid #d3d3d3;
        margin-bottom: 6rem;
      }
      .input {
        font-size: 2.8rem;
        height: 9rem;
        background: #fff;
        margin-bottom: 2rem;
        border-radius: 1rem;;
        .MuiOutlinedInput-root {
          border-radius: 1rem;
        }
        .MuiInputBase-input {
          font-size: 2.8rem;
          height: 9rem;
          padding: 0 4rem;          
        }
      }
    
      .HBtn.blackbg {
        font-size: 2.8rem;
        height: 9rem;
        margin-top: 0;
        border-radius: 1rem;
      }
    }
  }  
}  